import { Avatar } from '@mui/material'
import { Box } from '@mui/system'

import { ReactComponent as CheckIcon } from '../../assets/check-icon.svg'
import CustomHeading from '../../components/Shared/Typography/CustomHeading'
import CustomText from '../../components/Shared/Typography/CustomText'

function UploadSuccess() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                mt: 25,
                mx: 'auto',
                width: '350px',
                textAlign: 'center'
            }}
        >
            <Avatar sx={{ width: '70px', height: '70px', mb: 1 }}>
                <CheckIcon />
            </Avatar>

            <CustomHeading heading="Thank You!" py={2} />

            <CustomText text="Your files have been submitted successfully." />
        </Box>
    )
}

export { UploadSuccess }
