import { Typography, Box } from '@mui/material'

const NoMediaText = ({ text }: any) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            my: 5,
            mx: 'auto',
            width: '350px',
            textAlign: 'center'
        }}
    >
        <Typography
            component="p"
            variant="body2"
            color="text.secondary"
            sx={{
                font: 'normal normal normal 13px/16px Inter',
                textAlign: 'center'
            }}
        >
            {text}
        </Typography>
    </Box>
)

export default NoMediaText
