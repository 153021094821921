import { Routes, Route } from 'react-router-dom'

import RedirectHomePage from '../components/Authentication/ClerkRedirect/RedirectHome'
import SSOCallback from '../components/Authentication/ClerkRedirect/SSOCallback'
import ForgotPassword from '../components/Authentication/Forms/ForgotPassword'
import Login from '../components/Authentication/Login'
import PrivacyPolicy from '../components/Shared/PrivacyPolicy/PrivacyPolicy'
import TermsConditions from '../components/Shared/TermsConditions/TermsConditions'
import { Dashboard } from '../pages/Dashboard'
import { EventCreateSuccess } from '../pages/Dashboard/ActiveEvents/EventCreateSuccess'
import MemoriesCollection from '../pages/MemoriesCollection/Index'
import MyInvites from '../pages/MyInvites/Index'
import MyMemories  from '../pages/MyMemories/Index'
import Contact from '../pages/Static/Home/Contact/Contact'
import Home from '../pages/Static/Home/Index'
import { Upload } from '../pages/Upload/Index'
import { UploadSuccess } from '../pages/Upload/UploadSuccess'
import { ViewEvent } from '../pages/ViewEvent/Index'


const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sso-callback" element={<SSOCallback />} />
        <Route path="/redirecting-home" element={<RedirectHomePage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/invites" element={<MyInvites />} />
        <Route path="/memories" element={<MyMemories />} />
        <Route path="/memories-collection/:eventId" element={<MemoriesCollection/>}/>
        <Route
            path="/event-success/:eventId"
            element={<EventCreateSuccess />}
        />
        <Route path="/view-event/:eventId/" element={<ViewEvent />} />
        <Route
            path="/upload-images/:eventId/:groupId/:inviteType"
            element={<Upload />}
        />
        <Route path="/upload-images/:eventId" element={<Upload />} />
        <Route path="/upload-images/:eventId/:groupId" element={<Upload />} />
        <Route path="/upload-success" element={<UploadSuccess />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
    </Routes>
)

export { AppRoutes }
