import { SecurityService } from './security.service'

class ContactUS {
    async contactUs(data: any) {
        return (await SecurityService.createHttpHandler()).post(
            `/contact_us/`,
            data
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ContactUS()
