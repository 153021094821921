import { Typography } from '@mui/material'

export default function ErrorValidationMessage(props: any) {
    return (
        <Typography
            variant="caption"
            display="block"
            gutterBottom
            color="error"
            textAlign="left"
            m={0}
        >
            {props?.message}
        </Typography>
    )
}
