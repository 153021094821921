import { Box, Grid, Typography, useMediaQuery } from '@mui/material'

import HeroBG from '../../../assets/Home/hero-bg.png'
import EnvelopeIcon from '../../../assets/Home/home-envelope-icon.svg'
import FlagIcon from '../../../assets/Home/home-flags-right.svg'
import FlagSmallIcon from '../../../assets/Home/home-flags-small-right.svg'
import ImageIcon from '../../../assets/Home/home-image-icon.svg'
import RocketIcon from '../../../assets/Home/home-rocket-icon.svg'
import { ReactComponent as SmileLovesIcon } from '../../../assets/Home/smile-loves-icon.svg'
import { ReactComponent as SmileSpectsIcon } from '../../../assets/Home/smile-spects-icon.svg'
import { ReactComponent as SmileStarsIcon } from '../../../assets/Home/smile-stars-icon.svg'
import SpecialMomentCard from '../../../components/Shared/Cards/SpecialMomentCard'
import theme from '../../../styles/theme'

export default function HowSpecialMomentsWorks() {
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    const eventBoxCircleStyle = {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        m: 'auto',
        zIndex: '10',
        position: 'relative'
    }

    const eventBoxStyle = {
        borderRadius: '10px 10px 0px 0px',
        mt: -3,
        pt: { xs: 2, sm: 8 },
        pb: { xs: 2, sm: 5 },
        px: 2
    }

    const eventBoxStyleAfter = {
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'left bottom',
        content: `''`,
        display: 'block',
        width: '110%',
        height: '20px',
        position: 'relative',
        top: { xs: '35px', md: '50px' },
        left: '0px',
        backgroundSize: '20px 32px',
        ml: '-16px'
    }

    return (
        <Box
            id="how-it-works"
            sx={{
                background: `url(${HeroBG})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                backgroundAttachment: 'fixed'
            }}
        >
            <Grid
                container
                sx={{
                    background: `#E2E2E250`,
                    '&:after': {
                        background: `linear-gradient(-45deg, transparent 16px, #E2E2E250 0),
        linear-gradient(45deg, transparent 16px, #E2E2E250  0)`,
                        backgroundRepeat: 'repeat-x',
                        backgroundPosition: 'left bottom',
                        content: `''`,
                        display: 'block',
                        width: '113%',
                        height: '16px',
                        position: 'relative',
                        top: '15px',
                        left: '0px',
                        backgroundSize: '15px 32px'
                    }
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        position: 'relative',
                        textAlign: 'right'
                    }}
                >
                    <Box
                        component="img"
                        src={isSmall ? FlagSmallIcon : FlagIcon}
                    />
                </Box>
                <Grid item xs={12} sm={12}>
                    <Box
                        data-sal="slide-right"
                        data-sal-delay="300"
                        data-sal-easing="ease-out-back"
                        sx={{
                            textAlign: 'center',
                            pt: { xs: 5, sm: 0 },
                            pb: { xs: 10, sm: 15 },
                            px: { xs: 6, sm: 3, md: 25 }
                        }}
                    >
                        <Typography variant="h3">
                            How Special Moments works!
                        </Typography>

                        <Typography variant="subtitle2">
                            Sign Up to Special Moments, create an event by
                            entering all the details, invite guests to collect
                            memories and finally publish the video.
                        </Typography>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={isSmall ? 5 : 2}
                            sx={{ mt: -3, textAlign: 'center' }}
                        >
                            <SpecialMomentCard
                                headIcon={EnvelopeIcon}
                                icon={
                                    <SmileSpectsIcon
                                        style={{ paddingRight: 2 }}
                                    />
                                }
                                title="Invite Participants"
                                description="Invite participants to upload pictures, videos or audio files by sharing them a link."
                                headStyles={{
                                    ...eventBoxCircleStyle,
                                    background:
                                        '#FFFFFF 0% 0% no-repeat padding-box',
                                    boxShadow: '5px 5px 0px #8CC63F66'
                                }}
                                styles={{
                                    background:
                                        '#e4ecda 0% 0% no-repeat padding-box',
                                    ...eventBoxStyle,
                                    '&:after': {
                                        background: `linear-gradient(-45deg, transparent 16px, #e4ecda 0),
                  linear-gradient(45deg, transparent 16px, #e4ecda  0)`,
                                        ...eventBoxStyleAfter
                                    }
                                }}
                            />
                            <SpecialMomentCard
                                headIcon={ImageIcon}
                                icon={
                                    <SmileLovesIcon
                                        style={{ paddingRight: 2 }}
                                    />
                                }
                                title="Collect Files"
                                description="Manage the uploads that participants are
                                uploading. Check and download the
                                images, videos and audio files."
                                headStyles={{
                                    ...eventBoxCircleStyle,
                                    background:
                                        '#FFFFFF 0% 0% no-repeat padding-box',
                                    boxShadow: '5px 5px 0px #EB582980'
                                }}
                                styles={{
                                    background:
                                        '#f2dfd8 0% 0% no-repeat padding-box',
                                    ...eventBoxStyle,
                                    '&:after': {
                                        background: `linear-gradient(-45deg, transparent 16px, #f2dfd8 0),
              linear-gradient(45deg, transparent 16px, #f2dfd8  0)`,
                                        ...eventBoxStyleAfter
                                    }
                                }}
                            />
                            <SpecialMomentCard
                                headIcon={RocketIcon}
                                icon={
                                    <SmileStarsIcon
                                        style={{ paddingRight: 2 }}
                                    />
                                }
                                title="Publish Video"
                                description="Finally upload the video that you
                                created by downloading the images. It’s a wrap!"
                                headStyles={{
                                    ...eventBoxCircleStyle,
                                    background:
                                        '#FFFFFF 0% 0% no-repeat padding-box',
                                    boxShadow: '5px 5px 0px #3973B766'
                                }}
                                styles={{
                                    background:
                                        '#dbe4eb 0% 0% no-repeat padding-box',
                                    ...eventBoxStyle,
                                    '&:after': {
                                        background: `linear-gradient(-45deg, transparent 16px, #dbe4eb 0),
              linear-gradient(45deg, transparent 16px, #dbe4eb  0)`,
                                        ...eventBoxStyleAfter
                                    }
                                }}
                            />
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
