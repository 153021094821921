import { SyntheticEvent, useState } from 'react'

import { Box, Card, Tab, Tabs } from '@mui/material'

import { AddGroupForm } from './AddGroupForm'
import { AddInviteForm } from './AddInviteForm'
import { TabPanel } from '../../../../components/Shared/Tabs/TabPanel'

function AddInviteCard(props: any) {
    const [value, setValue] = useState(0)

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    width: '100%',
                    borderRadius: '4px',
                    border: '1px solid',
                    borderColor: 'secondary.contrastText',
                    mt: 2,
                    boxShadow: 'none'
                }}
            >
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        centered
                        sx={{
                            backgroundColor: 'secondary.dark',
                            '.Mui-selected': {
                                color: 'primary.light'
                            }
                        }}
                        TabIndicatorProps={{
                            color: 'primary.light'
                        }}
                    >
                        <Tab
                            label="Invite"
                            sx={{
                                width: '100%',
                                textTransform: 'capitalize',
                                fontSize: '13px'
                            }}
                        />
                    </Tabs>
                    <Box>
                        <TabPanel value={value} index={0}>
                            <AddInviteForm
                                event={props?.event}
                                addCallBackEvent={props?.addCallBackEvent}
                            />
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <AddGroupForm
                                event={props?.event}
                                addCallBackEvent={props?.addCallBackEvent}
                            />
                        </TabPanel>
                    </Box>
                </Box>
            </Card>
        </>
    )
}

export { AddInviteCard }
