/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';

import { Box, Button, Grid, TextField } from '@mui/material';

import { ReactComponent as AddIcon } from '../../../../assets/circle-plus-solid-icon.svg';
import { ReactComponent as InviteSuccessIcon } from '../../../../assets/invite-success-icon.svg';
import { ReactComponent as SendInviteIcon } from '../../../../assets/paper-plane-solid-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/trash-solid-icon-red.svg';
import CustomLoadingButton from '../../../../components/Shared/Button/CustomLoadingButton';
import CustomizedDialogs from '../../../../components/Shared/Dialogs/CustomizedDialogs';
import DialogSuccess from '../../../../components/Shared/Dialogs/DialogSuccess';
import ErrorValidationMessage from '../../../../components/Shared/ErrorMessage/ErrorValidationMessage';
import isValidEmail from '../../../../hooks/IsValidEmail';
import EventService from '../../../../services/event.service';

function AddInviteForm(props: any) {
    const [btnLoading, setBtnLoading] = useState(false);
    const [infoMsg, setInfoMsg] = useState<string | null>(null);
    const [inviteFormFields, setInviteFormFields] = useState([
        { name: '', email: '', isDelete: false }
    ]);
    const [userFormData, setUserFormData] = useState<{
        name: string;
        email: string;
    }>({
        name: '',
        email: ''
    });

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const handleSuccessDialogClose = () => {
        setShowSuccessDialog(false);
        props?.addCallBackEvent(1, 0);
    };

    const handleNameChange = (event: any) => {
        setUserFormData({ ...userFormData, name: event.target.value });
    };

    const handleEmailChange = (event: any) => {
        setUserFormData({ ...userFormData, email: event.target.value });
    };

    const handleAddInvitee = () => {
        const isValidMail = isValidEmail(userFormData.email);
        if (!isValidMail) {
            setInfoMsg('Invalid Email address');
            return;
        }
        if (
            inviteFormFields.filter(
                (item: any) => item.email === userFormData.email
            ).length < 1 &&
            userFormData.name !== '' &&
            userFormData.email !== ''
        ) {
            const userObject = {
                name: userFormData.name,
                email: userFormData.email,
                isDelete: true
            };
            setUserFormData({ name: '', email: '' });
            setInviteFormFields([...inviteFormFields, userObject]);
            if (infoMsg) {
                setInfoMsg(null);
            }
        } else {
            setInfoMsg('The email address has already been provided.');
        }
    };

    const handleRemoveInvitee = (index: any) => {
        const data = [...inviteFormFields];
        data.splice(index, 1);
        setInviteFormFields(data);
    };

    const handleSendInvitees = async () => {
        setInfoMsg(null);
        if (inviteFormFields.length > 0 && inviteFormFields.some((item: any) => item.email !== '' && item.name !== '')) {
            const validInviteFormFields = inviteFormFields.filter((x) => x.email !== '' && x.name !== '' && isValidEmail(x.email));
   
            const invites = validInviteFormFields.map((item: any) => ({
                email: item.email,
                name: item.name
            }));
   
            const request = {
                invites,
                event_id: props?.event.event_id
            };
   
            setBtnLoading(true);
   
            try {
                await EventService.addInvitees(request);
                setBtnLoading(false);
                setInviteFormFields([{ name: '', email: '', isDelete: false }]);
                setShowSuccessDialog(true);
            } catch (error:any) {
                if (error?.response?.status === 400) {
                    setInfoMsg('Invalid data, please fill again');
                } else if (error?.response?.status === 404) {
                    setInfoMsg(error?.response?.data);
                }
                setBtnLoading(false);
            }
        } else {
            setInfoMsg('Please provide valid data');
        }
    };


    return (
        <>
            <Box sx={{ width: '100%' }}>
                {inviteFormFields.map((form: any, index: number) => (
                    <Grid
                        container
                        component="main"
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        key={form.email}
                        sx={{
                            mb: 1,
                            borderBottom: {
                                xs: '1px solid',
                                sm: 'none'
                            },
                            borderColor: 'secondary.contrastText'
                        }}
                    >
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                id="name"
                                name="name"
                                placeholder="Name"
                                autoFocus
                                onChange={(event) => handleNameChange(event)}
                                value={form.name || userFormData.name}
                                size="small"
                                inputProps={{ style: { fontSize: '14px' } }}
                                sx={{
                                    borderRadius: '4px',
                                    borderColor: 'secondary.contrastText',
                                    color: 'primary.light',
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            justifyItems="right"
                            justifyContent="right"
                        >
                            <TextField
                                variant="outlined"
                                id="email"
                                name="email"
                                placeholder="Email"
                                onChange={(event) => handleEmailChange(event)}
                                value={form.email || userFormData.email}
                                size="small"
                                inputProps={{ style: { fontSize: '14px' } }}
                                sx={{
                                    borderRadius: '4px',
                                    borderColor: 'secondary.contrastText',
                                    color: 'primary.contrastText',
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={2}
                            justifyItems="right"
                            justifyContent="right"
                            alignItems="center"
                        >
                            {!form.isDelete ? (
                                <Button
                                    size="small"
                                    variant="text"
                                    onClick={handleAddInvitee}
                                    startIcon={<AddIcon />}
                                    sx={{
                                        fontSize: '11px',
                                        textTransform: 'capitalize',
                                        mt: '4px'
                                    }}
                                >
                                    add
                                </Button>
                            ) : (
                                <Button
                                    size="small"
                                    variant="text"
                                    onClick={() => handleRemoveInvitee(index)}
                                    startIcon={
                                        <DeleteIcon style={{ color: 'red' }} />
                                    }
                                    sx={{
                                        fontSize: '11px',
                                        textTransform: 'capitalize',
                                        mt: '4px',
                                        color: 'red'
                                    }}
                                >
                                    Delete
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                ))}
            </Box>
            {infoMsg && <ErrorValidationMessage message={infoMsg} />}
            <Box sx={{ textAlign: 'right', mt: 2 }}>
                <CustomLoadingButton
                    onClick={handleSendInvitees}
                    startIcon={<SendInviteIcon />}
                    loading={btnLoading}
                    name="Send Invitation"
                    width="auto"
                />
            </Box>

            <CustomizedDialogs
                open={showSuccessDialog}
                onClose={() => setShowSuccessDialog(false)}
                onManage={handleSuccessDialogClose}
                header="Invitation Sent Successfully"
                message="Your invitation to your invitees is on their way"
                continueBtn="Manage Invitees"
                icon={InviteSuccessIcon}
                component={DialogSuccess}
            />
        </>
    );
}

export { AddInviteForm };
