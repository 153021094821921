import { SyntheticEvent, useEffect, useState } from 'react'

import { Box, Card, Tab, Tabs } from '@mui/material'

import { ListGroups } from './ListGroups'
import { ListInvitees } from './ListInvitees'
import NoDataLabel from '../../../../components/Shared/Label/NoDataLabel'
import { TabPanel } from '../../../../components/Shared/Tabs/TabPanel'
import EventService from '../../../../services/event.service'

function Invited(props: any) {
    const [value, setValue] = useState(props?.invitedSubTab)
    const [eventInvites, setEventInvites] = useState<any>()
    const [eventGroups, setEventGroups] = useState<any>()

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const getEventInvites = async () => {
        await EventService.getEventInvites(props?.event?.event_id)
            .then((response: any) => {
                setEventInvites(response.data)
            })
            .catch(() => {})
    }

    const getEventGroups = async () => {
        await EventService.getEventGroups(props?.event?.event_id)
            .then((response: any) => {
                setEventGroups(response.data)
            })
            .catch(() => {})
    }

    const loadData = async () => {
        await Promise.all([getEventInvites(), getEventGroups()])
    }

    useEffect(() => {
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    width: '100%',
                    borderRadius: '4px',
                    border: '1px solid',
                    borderColor: 'secondary.contrastText',
                    mt: 2,
                    boxShadow: 'none'
                }}
            >
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        centered
                        sx={{
                            backgroundColor: 'secondary.dark',
                            '.Mui-selected': {
                                color: 'primary.light'
                            }
                        }}
                        TabIndicatorProps={{
                            color: 'primary.light'
                        }}
                    >
                        <Tab
                            label="Invite"
                            sx={{
                                width: '50%',
                                textTransform: 'capitalize',
                                fontSize: '13px'
                            }}
                        />
                    </Tabs>
                    <Box>
                        <TabPanel value={value} index={0}>
                            {eventInvites?.invites?.length > 0 ? (
                                <ListInvitees
                                    event={props?.event}
                                    eventInvites={eventInvites}
                                />
                            ) : (
                                <NoDataLabel />
                            )}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {eventGroups?.length > 0 ? (
                                <ListGroups
                                    event={props?.event}
                                    eventGroups={eventGroups}
                                />
                            ) : (
                                <NoDataLabel />
                            )}
                        </TabPanel>
                    </Box>
                </Box>
            </Card>
        </>
    )
}

export { Invited }
