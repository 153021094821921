import { useEffect } from 'react'


import { useAuth } from '@clerk/clerk-react'
import { Box, Typography, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import facebookIcon from '../../assets/facebook-icon.png'
import googleIcon from '../../assets/google-icon.png'
import LoginIcon from '../../assets/login-icon.svg'
import { OutlinedButtons } from '../Shared/Button/OutlinedButtons'
import { OutlinedIconButtons } from '../Shared/Button/OutlinedIconButtons'
import CustomHeading from '../Shared/Typography/CustomHeading'
import CustomText from '../Shared/Typography/CustomText'

function SocialLogin(props: any) {
    const navigate = useNavigate()
    const { isSignedIn } = useAuth()

    useEffect(() => {
        if (isSignedIn) {
            navigate('/dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn])

    return (
        <>
            <CustomHeading heading="Hi, Welcome!" />
            <CustomText text="To continue please use the below options" />
            <Box
                component="img"
                src={LoginIcon}
                sx={{
                    maxHeight: '180px',
                    maxWidth: '257px',
                    mb: { xs: 4, sm: 4, md: 4, lg: 4 },
                    mt: { xs: 2, sm: 0, md: 0, lg: 0 }
                }}
            />
            <Box component="form" noValidate sx={{ textAlign: 'center' }}>
                <Box sx={{ mb: 2 }} component="div">
                    <OutlinedIconButtons
                        text="Continue with Google"
                        icon={googleIcon}
                        onClick={() =>
                            props?.handleSignInWithSocial('oauth_google')
                        }
                    />
                </Box>
                <Box sx={{ mb: 2 }} component="div">
                    <OutlinedIconButtons
                        text="Continue with Facebook"
                        icon={facebookIcon}
                        onClick={() =>
                            props?.handleSignInWithSocial('oauth_facebook')
                        }
                    />
                </Box>
                <Box sx={{ mt: 1, mb: 2 }}>
                    <Divider>
                        <Typography
                            variant="body2"
                            component="span"
                            sx={{
                                color: '#1C1C1C',
                                font: 'normal normal normal 13px/16px Inter'
                            }}
                        >
                            OR
                        </Typography>
                    </Divider>
                </Box>
                <Box sx={{ mb: 2 }} component="div">
                    <OutlinedButtons
                        sx={{
                            color: '#023E8A'
                        }}
                        text="Sign In using Email"
                        onClick={() => props?.setShowSocialLogin(false)}
                    />
                </Box>
            </Box>
        </>
    )
}

export { SocialLogin }
