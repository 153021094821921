import { useEffect, useState } from 'react'
 
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
 
import { PreviewVideo } from './PreviewVideo'
import PublishedDetails from './PublishedDetails'
import { ReactComponent as PublishSuccessIcon } from '../../../assets/publish-success-icon.svg'
import CustomLoadingButton from '../../../components/Shared/Button/CustomLoadingButton'
import UploadFileButton from '../../../components/Shared/Button/UploadFileButton'
import SelectedFilesCard from '../../../components/Shared/Cards/SelectedFilesCard'
import CustomizedDialogs from '../../../components/Shared/Dialogs/CustomizedDialogs'
import DialogSuccess from '../../../components/Shared/Dialogs/DialogSuccess'
import CustomInput from '../../../components/Shared/Input/CustomInput'
import Form from '../../../components/Shared/Input/Form'
import Uploader from '../../../hooks/Uploader'
import EventService from '../../../services/event.service'
 
const uploadSchema = yup.object().shape({
    email: yup
        .string()
        .email('The email address is not valid.')
        .required('*required'),
    name: yup.string().required('*required'),
    message: yup.string().required('*required')
})
 
// eslint-disable-next-line max-lines-per-function
function PublishEvent(props: any) {
    const [fileList, setFileList] = useState<any | null>(null)
    const [isUploadStarted, setUploadStarted] = useState(false)
    const [showUploadFinalVideForm, setShowUploadFinalVideForm] = useState(
        !props?.event?.publish_video_url
    )
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [showResentSuccessDialog, setShowResendSuccessDialog] =
        useState(false)
    const [showPreview, setShowPreview] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
 
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onBlur',
        resolver: yupResolver(uploadSchema)
    })
 
    const files = fileList ? [...fileList] : []
    const handleFileSelect = (e: any) => {
        setFileList(e)
    }
 
    const sentGreetingsToRecipient = async () => {
        const request = {
            event_id: props?.event.event_id
        }
        await EventService.sendGreetings(request)
            .then(() => {
                setShowResendSuccessDialog(true)
            })
    }
 
    const handleSaveMedia = async (data:any) => {
        if (files.length > 0) {
            setUploadStarted(true);
            setBtnLoading(true);
            try {
                const file = files[0];
                const fileName = file.name;
                const uploader = new Uploader({ fileName, file });
                await uploader.start();
                const s3Url = uploader.getS3Url();
                const request = {
                    recipient_name: data?.name,
                    recipient_email: data?.email,
                    message: data?.message,
                    publish_video_url: s3Url
                };
                await EventService.eventUpdate(props?.event.event_id, request);
                setShowSuccessDialog(true);
                sentGreetingsToRecipient();
                props?.updateEventDataCallBack();
            }finally {
                setUploadStarted(false);
                setBtnLoading(false);
            }
        }
    };
    
 
    useEffect(() => {
        const defaultValues: any = {
            name: props?.event?.recipient_name,
            email: props?.event?.recipient_email,
            message: props?.event?.message
        }
        reset({ ...defaultValues })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showUploadFinalVideForm])
 
    const closeSuccessDialog = () => {
        setShowSuccessDialog(false)
        setShowUploadFinalVideForm(false)
    }
 
    return (
        <Box sx={{ width: '100%' }}>
            <Typography
                variant="h6"
                sx={{
                    font: 'normal normal medium 16px/20px Inter',
                    color: 'custom.gray'
                }}
            >
                Upload & Publish
            </Typography>
            <Divider sx={{ my: 1 }} />
 
            <Typography
                variant="subtitle1"
                sx={{
                    font: 'normal normal normal 13px/16px Inter',
                    color: 'custom.dark',
                    my: 1
                }}
            >
                Review your video here and make any changes if required
            </Typography>
 
            {showUploadFinalVideForm ? (
                <>
                    <Form
                        buttonLabel="Sign In"
                        register={register}
                        handleSubmit={handleSubmit}
                        onSubmit={handleSubmit(handleSaveMedia)}
                        btnLoading={btnLoading}
                        spacing={2}
                        sx={{ mt: 2 }}
                    >
                        <CustomInput
                            name="name"
                            type="text"
                            showLabel
                            placeholder="What is recipient name?"
                            error={errors?.name?.message}
                            xs={12}
                            md={12}
                        />
                        <CustomInput
                            name="email"
                            type="email"
                            showLabel
                            placeholder="What is recipient email?"
                            error={errors?.email?.message}
                            xs={12}
                            md={12}
                        />
                        <CustomInput
                            name="message"
                            type="text"
                            showLabel
                            placeholder="Message you want to send?"
                            error={errors?.message?.message}
                            xs={12}
                            md={12}
                            multiline
                        />
                    </Form>
                    <Grid
                        container
                        component="main"
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                    >
                        <Grid item xs={12} sm={12}>
                            <UploadFileButton
                                accept="video/*"
                                onChange={(e: any) =>
                                    handleFileSelect(e.target.files)
                                }
                            />
 
                            <SelectedFilesCard
                                files={files}
                                isUploadStarted={isUploadStarted}
                            />
                        </Grid>
 
                        <Grid item xs={12} sm={12}>
                            <Box
                                display="flex"
                                justifyContent="end"
                                alignItems="center"
                                sx={{
                                    minWidth: '100%',
                                    mb: { xs: 7, sm: 0 },
                                    mt: -2
                                }}
                            >
                                <CustomLoadingButton
                                    onClick={handleSubmit(handleSaveMedia)}
                                    loading={btnLoading}
                                    name="Submit"
                                    width="auto"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <PublishedDetails
                    sendGreetings={sentGreetingsToRecipient}
                    setShowPreview={() => setShowPreview(true)}
                    setShowUploadFinalVideForm={() =>
                        setShowUploadFinalVideForm(true)
                    }
                />
            )}
 
            <CustomizedDialogs
                open={showSuccessDialog}
                onClose={closeSuccessDialog}
                header="File Published Successfully!"
                message="Your surprise is on its way."
                continueBtn="View Publish Video"
                icon={PublishSuccessIcon}
                component={DialogSuccess}
            />
 
            <CustomizedDialogs
                open={showResentSuccessDialog}
                onClose={() => setShowResendSuccessDialog(false)}
                header="Your Greeting Sent Successfully!"
                message="Your surprise is on its way."
                continueBtn="Close"
                isClosed
                icon={PublishSuccessIcon}
                component={DialogSuccess}
            />
 
            <CustomizedDialogs
                open={showPreview}
                onClose={() => setShowPreview(false)}
                url={props?.event?.publish_video_url}
                component={PreviewVideo}
            />
        </Box>
    )
}
 
export { PublishEvent }
 