function isVideoUrl(url: any) {
    return (
        url
            .toUpperCase()
            .match(
                /\.(MP4|MPEG-4|MOV|WMV|AVI|AVCHD|FLV|F4V|SWF|MKV|WEBM|HTML5)$/
            ) != null
    )
}
export { isVideoUrl }

function isImageUrl(url: any) {
    return (
        url
            .toUpperCase()
            .match(
                /\.(APNG|AVIF|GIF|JPG|JPEG|JFIF|PJPEG|PJP|PNG|SVG|WEBP|BMP|ICO|TIFF|TIF0029)$/
            ) != null
    )
}
export { isImageUrl }

function isAudioUrl(url: any) {
    return url.toUpperCase().match(/\.(M4A|FLAC|MP3|WAV|WMA|AAC)$/) != null
}
export { isAudioUrl }
