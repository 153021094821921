import { FC, InputHTMLAttributes } from 'react'

import { Box, TextField } from '@mui/material'

import ErrorValidationMessage from '../ErrorMessage/ErrorValidationMessage'
import Label from '../Label/Label'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    label?: string
    error?: any
    register?: any
    wrapperClass?: string
    className?: string
    placeholder?: any
    xs?: any
    md?: any
    multiline?: boolean
    showLabel?: boolean
    sx?: any
    type?: string
}

const CustomInput: FC<InputProps> = ({
    register,
    name,
    error,
    placeholder,
    multiline,
    showLabel,
    sx,
    type,
    ...rest
}) => (
    <>
        {showLabel && (
            <Box display="flex" alignItems="start" flexDirection="column" >
                <Label title={placeholder} />
            </Box>
        )}

        <TextField
            {...register(name)}
            {...rest}
            type={type === undefined ? 'text' : type}
            name={name}
            variant="outlined"
            placeholder={placeholder}
            size="small"
            inputProps={{ style: { fontSize: '14px' } }}
            multiline={multiline}
            rows={multiline ? 4 : 1}
            sx={{
                ...sx,
                borderRadius: '4px',
                borderColor: 'secondary.contrastText',
                color: 'primary.contrastText',
                width: '100%',
                '& .MuiInputBase-root': {
                    mt: 0
                }
            }}
        />
        {showLabel && error && <ErrorValidationMessage message={error} />}
        {!showLabel && error && <ErrorValidationMessage message={error} />}
    </>
)

export default CustomInput
