import { createTheme } from '@mui/material/styles'

import Jenthill from './fonts/Jenthill.woff2'

declare module '@mui/material/styles' {
    interface PaletteOptions {
        custom: {
            pink: string
            orange: string
            dark: string
            gray: string
            blueGray: string
            lightGreen: string
            silver: string
        }
    }
}
const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
              font-family: 'Jenthill';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Jenthill'), local('Jenthill-Regular'), url(${Jenthill}) format('woff2');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
          `
        }
    },
    typography: {
        fontFamily: `"Inter", "sans-serif"`,
        fontSize: 16,
        h1: {
            fontSize: '30px',
            fontWeight: 'bold',
            marginBottom: '1rem',
            '@media (min-width:600px)': {
                fontSize: '40px'
            },
            '@media (min-width:960px)': {
                fontSize: '60px'
            }
        },
        h2: {
            fontWeight: 600,
            fontSize: '27px',
            lineHeight: '36px',
            paddingTop: '36px',
            margin: 'auto',
            textAlign: 'center'
        },
        h3: {
            fontWeight: 600,
            fontSize: '27px',
            lineHeight: '36px'
        },
        h4: {
            fontSize: '19px',
            fontWeight: 600,
            lineHeight: '22px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '2rem 0'
        },
        h5: {
            fontSize: '30px',
            padding: '8px',
            margin: '0 auto',
            lineHeight: '0',
            '@media (min-width:600px)': {
                fontSize: '25px'
            },
            '@media (min-width:960px)': {
                fontSize: '25px'
            }
        },
        h6: {
            fontWeight: 'normal'
        },
        subtitle1: {
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '20px',
            color: '#1C1C1C'
        },
        subtitle2: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '22px',
            color: 'common.black'
        },
        body1: {
            fontSize: '14px',
            fontWeight: 'normal'
        },
        body2: {
            fontWeight: 600,
            fontStyle: 'normal',
            fontSize: '11px',
            lineHeight: '14px',
            textAlign: 'center'
        }
    },
    palette: {
        primary: {
            light: '#023E8A',
            main: '#1C75BC',
            dark: '#023474',
            contrastText: '#001233'
        },
        secondary: {
            light: '#717171',
            main: '#8D8D8D',
            dark: '#F6F6F6',
            contrastText: '#E2E2E2'
        },
        common: {
            black: '#000000',
            white: '#ffffff'
        },
        custom: {
            pink: '#FF1B84',
            orange: '#FF9E00',
            dark: '#1c1c1c',
            gray: '#383838',
            blueGray: '#e5ebf3',
            lightGreen: '#00BBB1',
            silver: '#c6c6c6'
        }
    }
})

export default theme
