import { ReactNode } from 'react'

import { Box } from '@mui/material'

interface TabPanelProps {
    children?: ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        px: { xs: 1, sm: 3 },
                        py: { xs: 1, sm: 3 },
                        mb: { xs: 5, sm: 0 },
                        backgroundColor: 'transparent'
                    }}
                >
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    )
}

export { TabPanel }
