import { Divider } from '@mui/material'

import About from './About'
import Contact from './Contact/Contact'
import CreateEvent from './CreateEvent'
import EventType from './EventTypes'
import Footer from './Footer'
import HeroPage from './HeroPage'
import HowSpecialMomentsWorks from './HowSpecialMomentsWorks'
import { WeMakeItEasy } from './MakeItEasy'

export default function Home() {
    return (
        <>
            <HeroPage />

            <About />

            <HowSpecialMomentsWorks />

            <WeMakeItEasy />

            <EventType />

            <Contact />

            <CreateEvent />

            <Divider />

            <Footer />
        </>
    )
}
