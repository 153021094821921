import { useUser } from '@clerk/clerk-react'
import { Avatar } from '@mui/material'
import { Box } from '@mui/system'

import { ReactComponent as WelcomeIcon } from '../../../assets/welcome-icon.svg'
import CustomLoadingButton from '../../../components/Shared/Button/CustomLoadingButton'
import CustomHeading from '../../../components/Shared/Typography/CustomHeading'
import CustomText from '../../../components/Shared/Typography/CustomText'

function WelcomeMessage(props: any) {
    const { user } = useUser()
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                mt: 15
            }}
        >
            <Avatar sx={{ width: '70px', height: '70px' }}>
                <WelcomeIcon />
            </Avatar>

            <CustomHeading heading={`Welcome! ${user?.firstName}`} />

            <CustomText text="It looks like no event has been created yet. Let’s get started!" />

            <CustomLoadingButton
                onClick={props?.openCreateDialog}
                name="Create Event"
                width="auto"
            />
        </Box>
    )
}

export { WelcomeMessage }
