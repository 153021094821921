import { useState } from 'react'

import { Box, Divider, Tab, Tabs } from '@mui/material'

import { LoginForm } from './Forms/LoginForm'
import { RegisterForm } from './Forms/RegisterForm'
import { TabPanel } from '../Shared/Tabs/TabPanel'

function EmailLogin(props: any) {
    const [selectedTabValue, setTabValue] = useState(0)
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue)
    }
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={selectedTabValue}
                    onChange={handleTabChange}
                    sx={{
                        '.Mui-selected': {
                            color: 'primary.light'
                        }
                    }}
                    TabIndicatorProps={{
                        color: 'primary.light'
                    }}
                >
                    <Tab
                        label="Sign In"
                        sx={{
                            width: '50%',
                            textTransform: 'capitalize',
                            fontSize: '13px'
                        }}
                    />
                    <Tab
                        label="Sign Up"
                        sx={{
                            width: '50%',
                            textTransform: 'capitalize',
                            fontSize: '13px'
                        }}
                    />
                </Tabs>
                <Divider />
                <TabPanel value={selectedTabValue} index={0}>
                    <LoginForm setShowSocialLogin={props?.setShowSocialLogin} />
                </TabPanel>

                <TabPanel value={selectedTabValue} index={1}>
                    <RegisterForm
                        setShowSocialLogin={props?.setShowSocialLogin}
                    />
                </TabPanel>
            </Box>
        </>
    )
}

export { EmailLogin }
