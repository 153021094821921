import React from 'react'

import { Grid, Box,Link } from '@mui/material'

import ForgotPasswordForm from './ForgotPasswordForm'
import LoginBg from '../../../assets/login-bg.png'
import LogoIcon from '../../../assets/logo-sm.png'
import { forgotPasswordHeading, forgotPasswordText } from '../../../helpers/textHelper'
import CustomHeading from '../../Shared/Typography/CustomHeading'
import CustomText from '../../Shared/Typography/CustomText'
import LoginFooter from '../LoginFooter'

const ForgotPassword: React.FC = () => (
    <Grid
        container
        component="main"
        sx={{
            backgroundImage: `linear-gradient(0deg, #E2E2E235, #E2E2E235), url(${LoginBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <Grid
            item
            xs={12}
            sx={{
                m: 'auto',
                p: 3,
                textAlign: 'center',
                boxShadow: {
                    xs: 'none',
                    sm: '0px 0px 30px #0000000D',
                    md: '0px 0px 30px #0000000D',
                    lg: '0px 0px 30px #0000000D'
                },
                borderRadius: '4px',
                maxWidth: {
                    xs: '100%',
                    sm: '60%',
                    md: '50%',
                    lg: '32%'
                },
                bgcolor: { xs: 'transparent', sm: 'white' }
            }}
        >
            <Box
                component="img"
                src={LogoIcon}
                sx={{
                    mb: { xs: 4, sm: 4, md: 2, lg: 2 },
                    mt: { xs: 2, sm: 0, md: 0, lg: 0 }
                }}
            />
            <CustomHeading heading={forgotPasswordHeading} />
            <CustomText text={forgotPasswordText}/>
            <ForgotPasswordForm />
            <Box sx={{ mt: 1, mb: 2 }}>
                    <Link href="/login" variant="body2" sx={{
                    color: '#023E8A',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    fontSize: '13px',
                    fontWeight: '600'
                }}>Back to Login </Link>
                </Box>
            <LoginFooter />
        </Grid>
    </Grid>
)

export default ForgotPassword
