import { Avatar, Box, Divider, Grid, Typography } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'

import { AddInviteCard } from './AddInviteCard'
import { ReactComponent as CopyIcon } from '../../../../assets/copy-icon.svg'
import { ReactComponent as WhatsAppIcon } from '../../../../assets/whatsapp-icon.svg'

function Invite(props: any) {
    const sharableLink = `${window.origin}/upload-images/${props?.event.event_id}?share=copy`
    const whatsAppLink = `https://api.whatsapp.com/send?text=${sharableLink}`
    return (
        <>
            <Grid
                container
                component="main"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={7} sm={6}>
                    <Typography
                        component="p"
                        variant="body2"
                        sx={{
                            font: 'normal normal bold 13px/16px Inter',
                            color: 'custom.dark'
                        }}
                    >
                        Share it with your friends & family.
                    </Typography>
                    <Typography
                        component="p"
                        variant="body2"
                        sx={{
                            font: 'normal normal 300 11px/14px Inter',
                            mt: 1
                        }}
                    >
                        Share the invitation to your family and friends via
                        social media or copy and paste it your closed ones.
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={3}
                    sm={6}
                    justifyItems="center"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box sx={{ float: 'right', display: 'flex' }}>
                        <CopyToClipboard
                            text={sharableLink}
                            onCopy={() => toast.success(`Sharable URL copied!`)}
                        >
                            <Avatar
                                sx={{
                                    bgcolor: 'white ',
                                    border: '1px solid',
                                    borderColor: 'custom.silver',
                                    borderRadius: '4px',
                                    ml: 1,
                                    cursor: 'pointer'
                                }}
                                variant="square"
                            >
                                <CopyIcon />
                            </Avatar>
                        </CopyToClipboard>
                            <Avatar
                                component="a"
                                href={whatsAppLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-action="share/whatsapp/share"
                                sx={{
                                    bgcolor: 'white ',
                                    border: '1px solid',
                                    borderColor: 'custom.silver',
                                    borderRadius: '4px',
                                    ml: 1,
                                    cursor: 'pointer'
                                }}
                                variant="square"
                            >
                                <WhatsAppIcon />
                            </Avatar>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ mt: 4, mb: 2 }}>
                <Divider>
                    <Typography
                        variant="body2"
                        component="span"
                        sx={{
                            color: 'custom.dark',
                            font: 'normal normal normal 13px/16px Inter'
                        }}
                    >
                        OR
                    </Typography>
                </Divider>
            </Box>

            <Grid
                container
                component="main"
                direction="row"
                justifyContent="space-between"
            >
                <Grid item md={12}>
                    <Typography
                        component="p"
                        variant="body2"
                        sx={{
                            font: 'normal normal bold 13px/16px Inter',
                            color: 'custom.dark'
                        }}
                    >
                        Invite via email 
                    </Typography>
                    <Typography
                        component="p"
                        variant="body2"
                        sx={{
                            font: 'normal normal 300 11px/14px Inter',
                            mt: 1
                        }}
                    >
                        Enter {`invitee's`} name and email below and ask invitees to upload in group.
                    </Typography>
                </Grid>
            </Grid>

            <AddInviteCard
                event={props?.event}
                addCallBackEvent={props?.addCallBackEvent}
            />
        </>
    )
}

export { Invite }
