import axios from 'axios';

import EventService from "../services/event.service";

interface UploaderOptions {
    fileName: string;
    file: File;
}

interface Part {
    signedUrl: string;
    PartNumber: number;
}

interface ETagPart {
    PartNumber: number;
    ETag: string;
}

class Uploader {
    private fileName: string;

    private file: File;

    private chunkSize: number;

    private parts: Part[];

    private etags: ETagPart[];

    private fileId?: string;

    private fileKey?: string;

    constructor({ fileName, file }: UploaderOptions) {
        this.fileName = fileName;
        this.file = file;
        this.chunkSize = 5 * 1024 * 1024;
        this.parts = [];
        this.etags = [];
    }

    async initialize() {
        try {
            const requestData = {
                parts: [Math.ceil(this.file.size / this.chunkSize)],
                file: [this.file.name]
            };
            const initializeResponse = await EventService.getAwsResponse(requestData);
            const AWSFileDataOutput = initializeResponse.data[0];
            this.fileId = AWSFileDataOutput.fileId;
            this.fileKey = AWSFileDataOutput.fileKey;
            this.parts = AWSFileDataOutput.parts;
        } catch (error) {
            throw new Error('Failed to initialize upload');
        }
    }

    async uploadPart(partNumber: number, blob: Blob) {
        const part = this.parts.find(p => p.PartNumber === partNumber);
        if (!part) {
            throw new Error(`No pre-signed URL found for part ${partNumber}`);
        }
        try {
            const response = await axios.put(part.signedUrl, blob, {
                headers: {
                    'Content-Type': blob.type
                }
            });
            if (response.status === 200 && response.statusText === 'OK') {
                const eTag = response.headers.etag;
                if (eTag) {
                    const cleanedETag = eTag.replace(/"/g, '');
                    this.etags.push({ PartNumber: partNumber, ETag: cleanedETag });
                } else {
                    throw new Error(`No ETag received for part ${partNumber}`);
                }
            } else {
                throw new Error(`Failed to upload part ${partNumber}`);
            }
        } catch (error) {
            throw new Error(`Failed to upload part ${partNumber}`);
        }
    }

    async start() {
        await this.initialize();
        const totalParts = this.parts.length;

        const uploadPromises = [];
        for (let i = 0; i < totalParts; i += 1) {
            const start = i * this.chunkSize;
            const end = Math.min(start + this.chunkSize, this.file.size);
            const blob = this.file.slice(start, end);
            uploadPromises.push(this.uploadPart(i + 1, blob));
        }

        await Promise.all(uploadPromises);
        await this.finalize();
    }

    onError(callback: (error: Error) => void) {
        this.errorCallback = callback;
        return this;
    }

    private errorCallback: (error: Error) => void = () => {};

    getS3Url() {
        const baseUrl = process.env.REACT_APP_S3_URL;
        return `${baseUrl}/${this.fileKey}`;
    }

    async finalize() {
        try {
            if (!this.fileId || !this.fileKey) {
                throw new Error("fileId or fileKey is undefined");
            }
            const finalizationData = {
                data: [{
                    fileId: this.fileId,
                    fileKey: this.fileKey,
                    parts: this.etags.map(({ PartNumber, ETag }) => ({
                        signedUrl: this.parts.find(part => part.PartNumber === PartNumber)?.signedUrl || "",
                        PartNumber,
                        ETag
                    }))
                }]
            };
            await EventService.sendFinalizationData(finalizationData);

        } catch (error) {
            throw new Error('Failed to finalize upload');
        }
    }
}

export default Uploader;
