import { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import { Box, Fab } from '@mui/material'

import { CreateEvent } from './CreateEvent'
import { WelcomeMessage } from './WelcomeMessage'
import CustomizedDialogs from '../../../components/Shared/Dialogs/CustomizedDialogs'
import { EventCard } from '../EventCard'

function ActiveEvents(props: any) {
    const [open, setOpen] = useState(false)
    const handleEventCreateDialog = () => setOpen(true)

    const handleClose = () => {
        setOpen(false)
    }

    const events = props?.events?.filter(
        (x: any) => x.status === 'Active'
    )

    return (
        <>
            {events?.length === 0 ? (
                <WelcomeMessage openCreateDialog={handleEventCreateDialog} />
            ) : (
                <>
                    <EventCard events={events} past={false}/>
                    <Box
                        component="div"
                        sx={{
                            '& > :not(style)': {
                                m: 1,
                                position: 'fixed',
                                bottom: '3%',
                                right: '5%'
                            }
                        }}
                    >
                        <Fab
                            component="div"
                            sx={{
                                width: '50px',
                                height: '50px',
                                background: '#023E8A',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'primary.dark'
                                }
                            }}
                            onClick={handleEventCreateDialog}
                        >
                            <AddIcon />
                        </Fab>
                    </Box>
                </>
            )}
            <CustomizedDialogs
                open={open}
                onClose={handleClose}
                component={CreateEvent}
            />
        </>
    )
}
export { ActiveEvents }
