import React, { FC, InputHTMLAttributes, useState } from 'react'

import { Box, MenuItem, Select } from '@mui/material'

import ErrorValidationMessage from '../ErrorMessage/ErrorValidationMessage'
import Label from '../Label/Label'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    label?: string
    error?: any
    register?: any
    wrapperClass?: string
    className?: string
    placeholder?: any
    xs?: any
    md?: any
    control?: any
    showLabel: boolean
    selectOption?: any
}

const CustomSelectInput: FC<InputProps> = ({
    register,
    name,
    error,
    placeholder,
    showLabel,
    selectOption,
    control,
    ...rest
}) => {
    const [isvalue, setIsValue]= useState<string>("");
     const handleOnChange = (event: any) =>{
       setIsValue(event.target.value);
     }
    return(
    <>
        {showLabel && (
            <Box display="flex" alignItems="start" flexDirection="column">
                <Label title={placeholder} />
            </Box>
        )}

        <Select
            {...register(name)}
            {...rest}
            variant="outlined"
            name={name}
            placeholder={placeholder}
            control={control}
            size="small"
            value={isvalue}
            onChange={handleOnChange}         
            sx={{
                borderRadius: '4px',
                borderColor: 'secondary.contrastText',
                color: 'primary.contrastText',
                width: '100%',
                '& .MuiButtonBase-root': {
                    marginTop: 0
                }
            }}
        >
            {selectOption
                ? selectOption?.map((option: any) => (
                      <MenuItem
                          key={option.value}
                          value={option.value}
                          sx={{
                              marginTop: 0,
                              '& .MuiList-root .MuiButtonBase-root': {
                                  marginTop: 0
                              }
                          }}
                      >
                          {option.label}
                      </MenuItem>
                  ))
                : null}
        </Select>
        {showLabel && error && <ErrorValidationMessage message={error} />}
    </>
)}

export default CustomSelectInput
