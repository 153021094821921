import {
    Box,
    Button,
    Grid,
    Hidden,
    Typography,
    useMediaQuery
} from '@mui/material'
import { useNavigate } from 'react-router-dom'


import HeroBG from '../../../assets/Home/hero-bg.png'
import SelfieIcon from '../../../assets/Home/selfie-girl-icon.png'
import theme from '../../../styles/theme'

export default function CreateEvent() {
    const navigate = useNavigate()
    const isSmall = useMediaQuery(theme.breakpoints.down('xs'))
    const isMedium = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <Box
            sx={{
                backgroundImage: `url(${HeroBG})`,
                backgroundColor: 'white',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                backgroundAttachment: 'fixed',
                mt: { xs: 0, sm: -5, md: -15 },
                mb: -0.8,
                textAlign: { xs: 'center', sm: 'left' }
            }}
        >
            <Box
                sx={{
                    textAlign: 'center',
                    pt: { xs: 2, sm: 5, md: 0 },
                    pb: { xs: 0, sm: 5, md: 0 },
                    px: { xs: 2, sm: 3, md: 20 }
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={isSmall ? 0 : isMedium ? 5 : 20}
                >
                    <Hidden only="xs">
                        <Grid item xs={12} sm={4} md={3}>
                            <Box
                                component="img"
                                src={SelfieIcon}
                                sx={{ height: '323px', width: '215px' }}
                            />
                        </Grid>
                    </Hidden>

                    <Grid item xs={12} sm={5} md={5}>
                        <Typography variant="h3">
                            We enable you to mark various occasions with
                            festivity.
                        </Typography>

                        <Typography variant="subtitle2">
                            Special Moments enable you to use the application on
                            various occasions other than just birthdays.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={3} md={3}>
                        <Button
                            onClick={() => navigate('/login')}
                            sx={{
                                backgroundColor: 'primary.light',
                                color: 'white',
                                textTransform: 'capitalize',
                                fontSize: '13px',
                                border: '1px solid',
                                borderColor: 'primary.light',
                                height: '50px',
                                px: 2,
                                '&:hover': {
                                    backgroundColor: 'white',
                                    color: 'primary.light'
                                }
                            }}
                        >
                            Create event
                        </Button>
                    </Grid>

                    <Hidden smUp>
                        <Grid item xs={12} sm={4} md={3}>
                            <Box
                                component="img"
                                src={SelfieIcon}
                                sx={{ height: '323px', width: '215px' }}
                            />
                        </Grid>
                    </Hidden>
                </Grid>
            </Box>
        </Box>
    )
}
