import styled from '@emotion/styled'
import { Tab } from '@mui/material'

interface StyledTabProps {
    label: string
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab
        disableRipple
        {...props}
        sx={{
            alignSelf: 'start'
        }}
    />
))(() => ({
    textTransform: 'none',
    color: '#1C1C1C',
    justifyContent: 'left',
    textAlign: 'left',
    display: 'flex',
    backgroundColor: 'transparent',
    fontSize: '13px',
    minHeight: '40px',
    '&.Mui-selected': {
        color: '#1C75BC',
        fontWeight: 600
    }
}))

const SideNavBarTab = styled((props: StyledTabProps) => (
    <Tab
        disableRipple
        {...props}
        sx={{
            alignSelf: 'start'
        }}
    />
))(() => ({
    textTransform: 'none',
    color: '#1C1C1C',
    justifyContent: 'left',
    textAlign: 'left',
    display: 'flex',
    backgroundColor: 'transparent',
    fontSize: '13px',
    minHeight: '40px',
    '&.Mui-selected': {
        color: '#1C75BC',
        fontWeight: 600,
        border: 12
    }
}))

export { StyledTab, SideNavBarTab }
