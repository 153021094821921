import { useEffect, useState } from 'react'
 
import { useAuth, useUser } from '@clerk/clerk-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
 
import CustomLoadingButton from '../../components/Shared/Button/CustomLoadingButton'
import UploadFileButton from '../../components/Shared/Button/UploadFileButton'
import SelectedFilesCard from '../../components/Shared/Cards/SelectedFilesCard'
import CustomDateInput from '../../components/Shared/Input/CustomDateInput'
import CustomInput from '../../components/Shared/Input/CustomInput'
import Form from '../../components/Shared/Input/Form'
import Uploader from '../../hooks/Uploader'
import EventService from '../../services/event.service'
 
const uploadSchema = yup.object().shape({
    email: yup
        .string()
        .email('The email address is not valid.')
        .required('*required'),
    name: yup.string().required('*required'),
    dob: yup.string(),
    relation: yup.string(),
    message: yup.string()
})
 
function UploadMedia(props: any) {
    const { isSignedIn } = useAuth()
    const { user } = useUser()
    const navigate = useNavigate()
    const { eventId, groupId, inviteType } = useParams()
    const [fileList, setFileList] = useState<any | null>(null)
    const [isUploadStarted, setUploadStarted] = useState(false)
    const [uploadedImages, setUploadedImages] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
 
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onBlur',
        resolver: yupResolver(uploadSchema)
    })
 
    const files = fileList ? [...fileList] : []
    const handleFileSelect = (e: any) => {
        setFileList(e)
    }
 
    const handleSaveMedia = async (userInputs: any) => {
        if (files.length > 0) {
            setUploadStarted(true)
            setBtnLoading(true)
            let i = 1
            Array.from(files).forEach(async (file) => {
                const fileName= file.name
                const uploader = new Uploader({ fileName, file });
                await uploader.start();
                const s3Url = uploader.getS3Url();
                const url = s3Url
                const newArray: any = uploadedImages
                newArray.push({ url, key:url })
                setUploadedImages(newArray)
                if (files.length === i) {
                    await handleFinalSubmit(userInputs)
                }
                i += 1
            })
        }else{ await handleFinalSubmit(userInputs)
        }
    }
 
    const handleFinalSubmit = async (userInputs: any) => {
        const request = {
            event_id: eventId,
            group_id: groupId,
            document_urls: uploadedImages,
            message: userInputs.message,
            email: userInputs.email,
            name: userInputs.name,
            invite_type: inviteType
        }
 
        await EventService.uploadDocuments(request)
            .then(() => {
                setBtnLoading(false)
                navigate('/upload-success')
            })
            .catch(() => {
                setBtnLoading(false)
            })
    }
 
    useEffect(() => {
        if (isSignedIn && isSignedIn !== undefined) {
            const defaultValues: any = {
                name: user?.firstName,
                email: user?.primaryEmailAddress?.emailAddress
            }
            reset({ ...defaultValues })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn])
 
    return (
        <Container
            sx={{
                backgroundColor: 'white',
                boxShadow: '0px 0px 30px #0000000D',
                height: '100%',
                width: '100%',
                mt: 2,
                py: 5,
                px: 5
            }}
        >
            <Box
                component="div"
                sx={{
                    px: { xs: 1, sm: 15 },
                    width: { xs: '100%', sm: '100%' }
                }}
            >
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                        font: 'normal normal 600 19px/24px Inter',
                        color: 'custom.dark',
                        textAlign: 'center',
                        pt: 3,
                        pb: 5,
                        fontSize: '25px',
                        lineHeight: 1.3
                    }}
                >
                    {`Hi! Let’s submit files for ${props?.event?.name}`}
                </Typography>
                <Form
                    buttonLabel="Sign In"
                    register={register}
                    handleSubmit={handleSubmit}
                    onSubmit={handleSubmit(handleSaveMedia)}
                    btnLoading={btnLoading}
                    submitBtn={false}
                >
                    <CustomInput name="name" type="text" showLabel placeholder="What is your name?" error={errors?.name?.message} xs={12} md={6} />
                    <CustomInput name="email" type="email" showLabel placeholder="What is your email address?" error={errors?.email?.message} xs={12} md={6} />
                    <CustomDateInput name="dob" type="text" showLabel placeholder="Your DOB? (Optional)" xs={12} md={6} control={control} />
                    <CustomInput name="relation" type="text" showLabel placeholder="How do you know?" xs={12} md={6} />
                    <CustomInput name="message" type="text" showLabel placeholder="What is the message?" xs={12} md={12} multiline />
                </Form>
 
                <Grid
                    container
                    component="main"
                    direction="row"
                    justifyContent="space-between"
                    spacing={1}
                >
                    <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                        <UploadFileButton
                            accept="image/*, audio/*, video/*"
                            onChange={(e: any) =>
                                handleFileSelect(e.target.files)
                            }
                        />
                        <SelectedFilesCard
                            files={files}
                            isUploadStarted={isUploadStarted}
                        />
                    </Grid>
 
                    <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}
                    >
                        <CustomLoadingButton
                            onClick={handleSubmit(handleSaveMedia)}
                            loading={btnLoading}
                            name="Submit"
                            width="110px"
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
 
export { UploadMedia }
 