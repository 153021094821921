import {
    Box,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    Typography
} from '@mui/material'

import NavItems from '../NavItems'

export default function MobileMenuDrawer(props: any) {
    const handleNavigationMobileChange = (link: string) => {
        props?.setCurrentLink(link)
    }

    const container =
        window !== undefined ? () => window.document.body : undefined
    return (
        <Box component="nav">
            <Drawer
                container={container}
                variant="temporary"
                open={props?.mobileOpen}
                onClose={props?.handleDrawerToggle}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: { xs: 170, sm: 300 }
                    }
                }}
            >
                <Box
                    onClick={props?.handleDrawerToggle}
                    sx={{ textAlign: 'center' }}
                >
                    <List>
                        {NavItems()
                            .filter((x: any) => x.show === true)
                            .map((item: any) => (
                                <ListItem key={item.value} disablePadding>
                                    <ListItemButton sx={{ textAlign: 'left' }}>
                                        <Typography
                                            component="a"
                                            href={item.value}
                                            onClick={() =>
                                                handleNavigationMobileChange(
                                                    item.value
                                                )
                                            }
                                            sx={{
                                                textDecoration: 'none',
                                                '&.MuiTypography-root': {
                                                    color:
                                                        props?.currentLink ===
                                                        item?.value
                                                            ? '#023E8A'
                                                            : '#8D8D8D',
                                                    fontWeight:
                                                        props?.currentLink ===
                                                        item?.value
                                                            ? '700'
                                                            : 'normal'
                                                }
                                            }}
                                        >
                                            {item.label}
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
    )
}
