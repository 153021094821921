import { Avatar, Button } from '@mui/material'

import { ReactComponent as UploadIcon } from '../../../assets/upload-new-icon.svg'

export default function UploadFileButton(props: any) {
    return (
        <Button
            variant="outlined"
            size="large"
            component="label"
            sx={{
                width: '100%',
                height: '65px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                border: '1px dashed',
                borderColor: 'custom.silver',
                borderRadius: '4px',
                color: 'secondary.main',
                font: 'normal normal normal 13px/16px Inter'
            }}
        >
            <Avatar sx={{ background: 'transparent' }}>
                <UploadIcon />
            </Avatar>
            Upload your files
            <input
                hidden
                accept={props?.accept}
                type="file"
                multiple
                onChange={props?.onChange}
            />
        </Button>
    )
}
