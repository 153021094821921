import { SyntheticEvent, useState } from 'react'

import { Box, Divider, Tab, Tabs } from '@mui/material'

import { ManageGroupUploads } from './Group'
import { ManageInviteeUploads } from './Invitees'
import NoDataLabel from '../../../components/Shared/Label/NoDataLabel'
import { TabPanel } from '../../../components/Shared/Tabs/TabPanel'

function ManageUploads(props: any) {
    const [value, setValue] = useState(0)

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                centered
                sx={{
                    '.Mui-selected': {
                        color: 'primary.light',
                        font: 'normal normal medium 16px/20px Inter'
                    }
                }}
                TabIndicatorProps={{
                    color: 'primary.light'
                }}
            >
                <Tab
                    label="Invitees"
                    sx={{ width: '50%', textTransform: 'capitalize' }}
                />
            </Tabs>
            <Divider />

            <Box>
                <TabPanel value={value} index={0}>
                    {props?.eventInvites?.invites?.length > 0 ? (
                        <ManageInviteeUploads
                            event={props?.event}
                            eventInvites={props?.eventInvites}
                        />
                    ) : (
                        <NoDataLabel />
                    )}
                </TabPanel>

                <TabPanel value={value} index={1}>
                    {props?.eventGroups?.length > 0 ? (
                        <ManageGroupUploads
                            event={props?.event}
                            eventInvites={props?.eventInvites}
                        />
                    ) : (
                        <NoDataLabel />
                    )}
                </TabPanel>
            </Box>
        </Box>
    )
}
export { ManageUploads }
