import { useState } from 'react'

import { Box, Button, Card, Grid, TextField } from '@mui/material'

import { ReactComponent as AddIcon } from '../../../../assets/circle-plus-solid-icon.svg'
import { ReactComponent as GroupInviteSuccessIcon } from '../../../../assets/group-invite-success-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/trash-solid-icon-red.svg'
import { ReactComponent as AddGroupIcon } from '../../../../assets/user-group-solid-icon.svg'
import CustomLoadingButton from '../../../../components/Shared/Button/CustomLoadingButton'
import CustomizedDialogs from '../../../../components/Shared/Dialogs/CustomizedDialogs'
import DialogSuccess from '../../../../components/Shared/Dialogs/DialogSuccess'
import isValidEmail from '../../../../hooks/IsValidEmail'
import EventService from '../../../../services/event.service'

// eslint-disable-next-line max-lines-per-function
function AddGroupForm(props: any) {
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    const [inviteFormFields, setInviteFormFields] = useState([
        { name: '', admin_email: '', admin_name: '', isDelete: false }
    ])

    const handleSuccessDialogClose = () => {
        setShowSuccessDialog(false)
        props?.addCallBackEvent(1, 1)
    }

    const handleTextFieldChange = (
        fieldName: string,
        event: any,
        index: any
    ) => {
        const data = [...inviteFormFields]
        if (fieldName === 'name') data[index].name = event.target.value
        else if (fieldName === 'admin_email')
            data[index].name = event.target.value
        else if (fieldName === 'admin_name')
            data[index].name = event.target.value
        setInviteFormFields(data)
    }

    const handleAddInvitee = (data: any) => {
        if (
            isValidEmail(data.admin_email) &&
            inviteFormFields.filter(
                (item: any) =>
                    item.name === data.name ||
                    item.admin_email === data.admin_email
            ).length < 2 &&
            data.name !== '' &&
            data.admin_name !== ''
        ) {
            const object = {
                name: '',
                admin_email: '',
                admin_name: '',
                isDelete: false
            }
            data.isDelete = true
            setInviteFormFields([object, ...inviteFormFields])
        }
    }

    const handleRemoveInvitee = (index: any) => {
        const data = [...inviteFormFields]
        data.splice(index, 1)
        setInviteFormFields(data)
    }

    const handleSendGroupInvites = async () => {
        setInviteFormFields(
            inviteFormFields.filter(
                (x) =>
                    x.admin_email !== '' &&
                    x.name !== '' &&
                    isValidEmail(x.admin_email)
            )
        )
        if (inviteFormFields.length > 0) {
            const groups = inviteFormFields.map((item: any) => ({
                admin_email: item.admin_email,
                admin_name: item.admin_name,
                name: item.name
            }))

            const request = {
                groups,
                event_id: props?.event.event_id
            }
            setBtnLoading(true)
            await EventService.addGroups(request)
                .then(() => {
                    setInviteFormFields([
                        {
                            name: '',
                            admin_email: '',
                            admin_name: '',
                            isDelete: false
                        }
                    ])
                    setShowSuccessDialog(true)
                    setBtnLoading(false)
                })
                .catch(() => {
                    setBtnLoading(false)
                })
        }
    }

    const customTextInputStyle = {
        borderRadius: '4px',
        borderColor: 'secondary.contrastText',
        color: 'primary.contrastText',
        width: '100%',
        bgcolor: 'white'
    }

    return (
        <>
            {inviteFormFields.map((form: any, index: any) => (
                <Card
                    key={form.name}
                    sx={{
                        borderRadius: '4px',
                        border: '1px solid',
                        borderColor: 'secondary.contrastText',
                        mt: 1,
                        p: 2,
                        boxShadow: 'none',
                        bgcolor: 'secondary.contrastText'
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <Grid container component="main" spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    id="name"
                                    name="name"
                                    placeholder="Name"
                                    onChange={(event) =>
                                        handleTextFieldChange(
                                            'name',
                                            event,
                                            index
                                        )
                                    }
                                    value={form.name}
                                    size="small"
                                    inputProps={{
                                        style: { fontSize: '14px' }
                                    }}
                                    sx={{
                                        ...customTextInputStyle
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    id="admin_name"
                                    name="admin_name"
                                    placeholder="Admin Name"
                                    onChange={(event) =>
                                        handleTextFieldChange(
                                            'admin_name',
                                            event,
                                            index
                                        )
                                    }
                                    value={form.admin_name}
                                    size="small"
                                    inputProps={{
                                        style: { fontSize: '14px' }
                                    }}
                                    sx={{
                                        ...customTextInputStyle
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    id="admin_email"
                                    name="admin_email"
                                    placeholder="Email"
                                    onChange={(event) =>
                                        handleTextFieldChange(
                                            'admin_email',
                                            event,
                                            index
                                        )
                                    }
                                    value={form.admin_email}
                                    size="small"
                                    inputProps={{
                                        style: { fontSize: '14px' }
                                    }}
                                    sx={{
                                        ...customTextInputStyle
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {!form.isDelete ? (
                            <Button
                                size="small"
                                variant="text"
                                onClick={() => handleAddInvitee(form)}
                                startIcon={<AddIcon />}
                                sx={{
                                    fontSize: '11px',
                                    textTransform: 'capitalize',
                                    mt: '4px'
                                }}
                            >
                                Create Group
                            </Button>
                        ) : (
                            <Button
                                size="small"
                                variant="text"
                                onClick={() => handleRemoveInvitee(index)}
                                startIcon={
                                    <DeleteIcon style={{ color: 'red' }} />
                                }
                                sx={{
                                    fontSize: '11px',
                                    textTransform: 'capitalize',
                                    mt: '4px',
                                    color: 'red'
                                }}
                            >
                                Delete
                            </Button>
                        )}
                    </Box>
                </Card>
            ))}

            <Box sx={{ textAlign: 'right', mt: 2 }}>
                <CustomLoadingButton
                    onClick={handleSendGroupInvites}
                    loading={btnLoading}
                    startIcon={<AddGroupIcon />}
                    name="Create Group"
                    width="auto"
                />
            </Box>

            <CustomizedDialogs
                open={showSuccessDialog}
                onClose={() => setShowSuccessDialog(false)}
                onManage={handleSuccessDialogClose}
                header="Group Created Successfully"
                continueBtn="Manage Groups"
                icon={GroupInviteSuccessIcon}
                component={DialogSuccess}
            />
        </>
    )
}

export { AddGroupForm }
