import { Box, Grid, Typography } from '@mui/material'

const SpecialMomentCard = ({
    headIcon,
    icon,
    title,
    description,
    headStyles,
    styles
}: any) => (
    <Grid item xs={12} sm={4}>
        <Box sx={headStyles}>
            <Box
                component="img"
                src={headIcon}
                style={{ margin: '27px auto' }}
            />
        </Box>

        <Box sx={styles}>
            <Typography variant="h4">
                {icon}
                {title}
            </Typography>
            <Typography variant="subtitle2">{description}</Typography>
        </Box>
    </Grid>
)

export default SpecialMomentCard
