import { useEffect, useState } from 'react'


import { Box, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { useNavigate, useParams } from 'react-router-dom'

import { ReactComponent as CheckIcon } from '../../../assets/check-icon.svg'
import CustomLoadingButton from '../../../components/Shared/Button/CustomLoadingButton'
import CustomHeading from '../../../components/Shared/Typography/CustomHeading'
import CustomText from '../../../components/Shared/Typography/CustomText'
import EventService from '../../../services/event.service'

function EventCreateSuccess() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const { eventId } = useParams()
    const [eventDetails, setEventDetails] = useState<any>()

    const getEventDetailsById = async () => {
        await EventService.getEventById(eventId)
            .then((response: any) => {
                setEventDetails(response.data)
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (!isLoading) {
            setIsLoading(true)
            getEventDetailsById()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId])

    return (
        <>
            {eventDetails && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        mt: 20,
                        mx: 'auto',
                        width: '400',
                        textAlign: 'center'
                    }}
                >
                    <Avatar sx={{ width: '70px', height: '70px', mb: 3 }}>
                        <CheckIcon />
                    </Avatar>

                    <CustomHeading
                        heading="Event Created Successfully"
                        fontSize="20px"
                    />

                    <CustomText text="Please choose below options to continue." />

                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Typography
                            component="span"
                            variant="body2"
                            sx={{
                                font: 'normal normal normal 13px/16px Inter',
                                color: 'secondary.main'
                            }}
                        >
                            Event Name:
                        </Typography>
                        <Typography
                            component="span"
                            variant="body2"
                            sx={{
                                font: 'normal normal normal 13px/16px Inter',
                                color: 'custom.gray',
                                fontWeight: '600',
                                ml: 1
                            }}
                        >
                            {eventDetails?.name}
                        </Typography>
                    </Box>

                    <CustomLoadingButton
                        onClick={() => navigate(`/view-event/${eventId}`)}
                        name="Invite Participants"
                        width="auto"
                    />
                </Box>
            )}
        </>
    )
}
export { EventCreateSuccess }
