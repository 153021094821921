import { Link, Typography } from '@mui/material'

export default function LoginFooter() {
    return (
        <>
            <Typography
                component="p"
                variant="body2"
                sx={{ font: 'normal normal normal 13px/24px Inter' }}
            >
                By continuing, you agree to Special Moments
            </Typography>

            <Typography
                variant="body2"
                color="text.primary"
                align="center"
                sx={{
                    font: 'normal normal 600 13px/24px Inter',
                    color: 'custom.dark'
                }}
            >
                <Link color="inherit" href="/privacy-policy" target="_blank">
                    Terms of Service
                </Link>
                {' & '}
                <Link color="inherit" href="/terms-conditions" target="_blank">
                    Privacy Policy
                </Link>{' '}
            </Typography>
        </>
    )
}
