class ClerkTokenService {
    async getToken() {
        return window?.Clerk?.session !== null
            ? window?.Clerk?.session?.getToken({ template: 'SM-Token' })
            : null
    }
}

const ClerkToken = new ClerkTokenService()

export default ClerkToken
