import { useEffect, useState } from 'react'

import { Container } from '@mui/material'
import { Box } from '@mui/system'

import Markdown from '../Markdown'

export default function TermsConditions() {
    const [post, setPost] = useState('')
    useEffect(() => {
        import('./TermsConditions.md').then((res) => {
            fetch(res.default)
                .then((res) => res.text())
                .then((res) => setPost(res))
        })
    })
    return (
        <Container>
            <Box sx={{ p: { xs: '20px 5%', sm: '20px 5%', md: '20px 10%' } }}>
                <Markdown className="markdown" key={post.substring(0, 40)}>
                    {post}
                </Markdown>
            </Box>
        </Container>
    )
}
