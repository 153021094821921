import { Typography } from '@mui/material'

const CustomText = ({ text, styles }: any) => (
    <Typography
        component="p"
        variant="body2"
        color="text.secondary"
        sx={{
            font: 'normal normal normal 13px/16px Inter',
            textAlign: 'center',
            ...styles
        }}
    >
        {text}
    </Typography>
)

export default CustomText
