import { Box, Typography } from '@mui/material'

export default function NoDataLabel() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                my: 5,
                mx: 'auto',
                width: '350px',
                textAlign: 'center'
            }}
        >
            <Typography
                component="span"
                variant="body2"
                sx={{
                    font: 'normal normal normal 13px/16px Inter',
                    color: 'secondary.main'
                }}
            >
                No data to show.
            </Typography>
        </Box>
    )
}
