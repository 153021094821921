import { Avatar, Box, Card, Grid, Typography } from '@mui/material';
import moment from 'moment';

import EventCardIcon from '../../../assets/event-card-birthday-icon.png';
import ArrowForward from '../ArrowForward';

const EventDetailsCard = ({
    eventName,
    eventImageUrl,
    eventDate,
    redirectUrl
}: any) => (
    <Grid item xs={6} md={3} lg={2} xl={2}>
        <Card>
            <Avatar
                sx={{
                    width: '100%',
                    height: '140px',
                    borderRadius: 0
                }}
                src={
                    eventImageUrl === '' || eventImageUrl === null
                        ? EventCardIcon
                        : eventImageUrl
                }
            />
            <Box sx={{ width: '100%' }}>
                <Grid display="flex" sx={{ p: 1 }}>
                    <Grid item xs={10} md={10}>
                        <Typography
                            gutterBottom
                            component="h6"
                            sx={{
                                font: 'normal normal 600 13px/16px Inter',
                                color: 'custom.gray',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1 !important',
                                WebkitBoxOrient: 'vertical' 
                            }}
                        >
                            {eventName}
                        </Typography>
                        <Typography
                            sx={{
                                font: 'normal normal normal 11px/14px Inter',
                                color: 'secondary.main'
                            }}
                        >
                            {moment(eventDate).format('MM-DD-YYYY')}
                        </Typography>
                    </Grid>
                    <ArrowForward redirectUrl={redirectUrl} />
                </Grid>
            </Box>
        </Card>
    </Grid>
);

export default EventDetailsCard;
