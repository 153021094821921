import React from 'react'
 
 
import { Avatar, Box } from '@mui/material'
import Typography from '@mui/material/Typography'

interface ExpiryTimestamp {
    days: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
}

function EventTimer({ expiryTimestamp }: { expiryTimestamp: ExpiryTimestamp }) {   
    return (
        <Box
            sx={{
                fontSize: '10px',
                display: 'flex',
                alignItems: 'center',
                mt: 2
            }}
        >
            <Box sx={{ fontSize: '10px', textAlign: 'center' }}>
                <Avatar
                    sx={{
                        bgcolor: 'secondary.contrastText',
                        color: 'primary.light',
                        font: 'normal normal 600 19px/23px Inter'
                    }}
                    variant="square"
                >
                    {expiryTimestamp.days}
                </Avatar>
                <Typography
                    sx={{
                        fontSize: '11px',
                        textAlign: 'center',
                        color: 'white',
                        mt: 1
                    }}
                >
                    Days
                </Typography>
            </Box>
 
            <Box sx={{ fontSize: '10px', textAlign: 'center', ml: 2 }}>
                <Avatar
                    sx={{
                        bgcolor: 'secondary.contrastText',
                        color: 'primary.light',
                        font: 'normal normal 600 19px/23px Inter'
                    }}
                    variant="square"
                >
                    {expiryTimestamp?.hours}
                </Avatar>
                <Typography
                    sx={{
                        fontSize: '11px',
                        textAlign: 'center',
                        color: 'white',
                        mt: 1
                    }}
                >
                    Hours
                </Typography>
            </Box>
 
            <Box sx={{ fontSize: '10px', textAlign: 'center', ml: 2 }}>
                <Avatar
                    sx={{
                        bgcolor: 'secondary.contrastText',
                        color: 'primary.light',
                        font: 'normal normal 600 19px/23px Inter'
                    }}
                    variant="square"
                >
                    {expiryTimestamp?.minutes}
                </Avatar>
                <Typography
                    sx={{
                        fontSize: '11px',
                        textAlign: 'center',
                        color: 'white',
                        mt: 1
                    }}
                >
                    Min
                </Typography>
            </Box>
 
            <Box sx={{ fontSize: '10px', textAlign: 'center', ml: 2 }}>
                <Avatar
                    sx={{
                        bgcolor: 'secondary.contrastText',
                        color: 'primary.light',
                        font: 'normal normal 600 19px/23px Inter'
                    }}
                    variant="square"
                >
                    {expiryTimestamp?.seconds}
                </Avatar>
                <Typography
                    sx={{
                        fontSize: '11px',
                        textAlign: 'center',
                        color: 'white',
                        mt: 1
                    }}
                >
                    Sec
                </Typography>
            </Box>
        </Box>
    )
}
export { EventTimer }