
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Grid, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const ArrowForward = ({ redirectUrl }: any) => {
    const navigate = useNavigate()

    return (
        <Grid
            item
            xs={2}
            sx={{
                p: 0,
                textAlign: 'right'
            }}
        >
            <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                sx={{
                    height: '24px',
                    width: '24px',
                    bgcolor: 'custom.blueGray'
                }}
                onClick={() => navigate(redirectUrl)}
            >
                <ArrowForwardIcon
                    sx={{
                        height: '14px',
                        width: '14px',
                        color: 'primary.light'
                    }}
                />
            </IconButton>
        </Grid>
    )
}

export default ArrowForward
