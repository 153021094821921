import { Typography } from '@mui/material'

const CustomHeading = ({ heading, fontSize, py }: any) => (
    <Typography
        component="h1"
        sx={{
            font: 'normal normal 600 28px/34px Inter',
            py: py !== undefined ? py : 1,
            ...(fontSize && { fontSize })
        }}
    >
        {heading}
    </Typography>
)

export default CustomHeading
