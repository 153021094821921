import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'


export default function DialogSuccess(props: any) {
    const navigate = useNavigate()

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center'
            }}
        >
            <Box sx={{ width: '70px', height: '70px', mb: 2 }}>
                <props.icon />
            </Box>

            <Typography
                component="h2"
                variant="h2"
                sx={{ font: 'normal normal 600 19px/24px Inter', py: 1 }}
            >
                {props?.header}
            </Typography>

            <Typography
                component="span"
                variant="body2"
                sx={{
                    font: 'normal normal normal 13px/19px Inter',
                    color: '#8D8D8D'
                }}
            >
                {props?.message}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    onClick={props?.onManage ?? props?.onClose}
                    sx={{
                        backgroundColor: 'primary.light',
                        color: 'white',
                        marginTop: '30px',
                        textTransform: 'capitalize',
                        fontSize: '13px',
                        px: 2,
                        '&:hover': {
                            backgroundColor: 'primary.dark'
                        }
                    }}
                >
                    {props?.continueBtn}
                </Button>
                {!props?.isClosed && (
                    <Button
                        onClick={() => navigate('/dashboard')}
                        sx={{
                            backgroundColor: 'secondary.contrastText',
                            color: 'custom.gray',
                            marginTop: '30px',
                            textTransform: 'capitalize',
                            fontSize: '13px',
                            ml: 2,
                            px: 2,
                            '&:hover': {
                                backgroundColor: '#c8c5c5'
                            }
                        }}
                    >
                        Go to Dashboard
                    </Button>
                )}
            </Box>
        </Box>
    )
}
