import { saveAs } from 'file-saver'
import JSZip from 'jszip'

export default function DownLoadAsZip(media: any) {
    const filename = 'All-Images'
    const zip = new JSZip()
    const folder = zip.folder('All-Images')
    media.forEach((media: any) => {
        const blobPromise = fetch(media.url).then((response) => {
            if (response.status === 200 || response.status === 0) {
                return Promise.resolve(response.blob())
            }
            return Promise.reject(new Error(response.statusText))
        })
        const name = media.url.substring(media.url.lastIndexOf('/'))
        folder?.file(name, blobPromise)
    })

    zip.generateAsync({ type: 'blob' })
        .then((blob: any) => saveAs(blob, filename))
        .catch()
}
