import { ClerkProvider } from '@clerk/clerk-react'
import { Toaster } from 'react-hot-toast'
import { useLocation } from 'react-router-dom'

import { AppRoutes } from './AppRoutes'
import Navbar from '../components/Navbar'

function App() {
    const frontendApi: any = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY
    const publishableKey: any = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

    const location = useLocation();
    const isLoginPage = location.pathname === '/login';
    const isForgotPasswordPage = location.pathname === '/forgot-password';

    return (
        <ClerkProvider
            frontendApi={frontendApi}
            publishableKey={publishableKey}
        >
            {!isLoginPage && !isForgotPasswordPage && <Navbar />}
            <Toaster position="top-center" reverseOrder={false} />
            <AppRoutes />
        </ClerkProvider>
    )
}

export default App