import { SecurityService } from './security.service'
 
class EventService {
    async eventCreate(data: any) {
        return (await SecurityService.createHttpHandler()).post(`/event/`, data)
    }
 
    async eventUpdate(eventId: string, data: any) {
        return (await SecurityService.createHttpHandler()).put(
            `/event/${eventId}/`,
            data
        )
    }
 
    async addInvitees(data: any) {
        return (await SecurityService.createHttpHandler()).post(
            `/event/invite/`,
            data
        )
    }
 
    async addGroups(data: any) {
        return (await SecurityService.createHttpHandler()).post(
            `/event/group/`,
            data
        )
    }
 
    async getEvents(email: string) {
        return (await SecurityService.createHttpHandler()).get(
            `event/?email=${email}`
        )
    }
 
    async getEventById(eventId: any, emailLinkAccees = true) {
        return (await SecurityService.createHttpHandler()).get(
            `event/${eventId}/?email_link_access=${emailLinkAccees}`
        )
    }
 
    async getEventDetailsById(eventId: string) {
        return (await SecurityService.createHttpHandler()).get(
            `event/${eventId}/`
        )
    }
 
    async getUserInvites(email: string) {
        return (await SecurityService.createHttpHandler()).get(
            `/event/invitations/?email=${email}`
        )
    }

    async getUserMemories(email: string){
        return (await SecurityService.createHttpHandler()).get(
            `/my/memories/?email=${email}`
        )
    }

    async getEachMemoriesCollection(email: string, eventId: string){
        return (await SecurityService.createHttpHandler()).get(
            `/greetings/send/?email=${email}&event_id=${eventId}`
        )
    }

    async getEventInvites(eventId: any) {
        return (await SecurityService.createHttpHandler()).get(
            `/event/invite/${eventId}/`
        )
    }
 
    async getEventGroups(eventId: any) {
        return (await SecurityService.createHttpHandler()).get(
            `/event/group/${eventId}/`
        )
    }
 
    async sendRemainderEmail(data: any) {
        return (await SecurityService.createHttpHandler()).post(
            `/remainder/greeting/`,
            data
        )
    }
 
    async uploadDocuments(data: any) {
        return (await SecurityService.createHttpHandler()).post(
            `/event/documents/`,
            data
        )
    }
 
    async getDocumentsByEventId(eventId: string) {
        return (await SecurityService.createHttpHandler()).get(
            `/event/${eventId}/documents/`
        )
    }
 
    async getInviterEvent(eventId: string, email: string) {
        return (await SecurityService.createHttpHandler()).get(
            `/inviter/greeting/?event_id=${eventId}&email=${email}`
        )
    }
 
    async getMediaByInvitee(eventId: string, inviteeEmail: string) {
        return (await SecurityService.createHttpHandler()).get(
            `/event/documents/?email=${inviteeEmail}&event_id=${eventId}`
        )
    }
 
    async getMediaByGroup(eventId: string, groupId: string) {
        return (await SecurityService.createHttpHandler()).get(
            `/event/documents/${eventId}/?group_id=${groupId}`
        )
    }
 
    async sendGreetings(data: any) {
        return (await SecurityService.createHttpHandler()).post(
            `/greetings/send/`,
            data
        )
    }
 
    async getAwsResponse(data: any){
        return (await SecurityService.createHttpHandler()).post(
            `/initial_get_multipart_presigned_url/`,
            data
        )
    }
 
    async  sendFinalizationData(data: any){
        return (await SecurityService.createHttpHandler()).post(
            `/final_multipart_upload/`,
            data
        )
    }
 
}
 
// eslint-disable-next-line import/no-anonymous-default-export
export default new EventService()