import { Box } from '@mui/system'

function PreviewVideo(props: any) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                width: { xs: '100%', sm: '500px' },
                height: '100%'
            }}
        >
            <video width="100%" controls>
                <source src={props?.url} />
            </video>
        </Box>
    )
}

export { PreviewVideo }
