import { Box, IconButton } from '@mui/material';

import { ReactComponent as AddIcon } from '../../assets/circle-plus-solid-white-icon.svg';
import UserIcon from '../../assets/user.svg';

interface EventImageUploaderProps {
    tempEventImage: string;
    eventImageUrl: string;
    handleFileSelect: (files: FileList | null) => void;
}

const EventImageUploader = ({
    tempEventImage,
    eventImageUrl,
    handleFileSelect
}: EventImageUploaderProps) => (
        <>
            <Box
                component="div"
                sx={{
                    width: '100px',
                    height: '100px',
                    border: tempEventImage
                        ? '2px solid #FFFFFF'
                        : eventImageUrl !== ''
                        ? '2px solid #FFFFFF'
                        : 'none',
                    borderRadius: '50px',
                    background: `url(${
                        tempEventImage || (eventImageUrl !== '' && eventImageUrl !== null
                            ? eventImageUrl
                            : UserIcon)
                    })`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            />
            <IconButton
                component="label"
                sx={{
                    position: 'absolute',
                    marginTop: '-30px',
                    marginLeft: '70px',
                    backgroundColor: '#7FD14D',
                    '&:hover': {
                        backgroundColor: '#54a324'
                    },
                    zIndex: 1000
                }}
            >
                <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(e) => handleFileSelect(e.target.files)}
                />
                <AddIcon />
            </IconButton>
        </>
    );

export default EventImageUploader;
