import { useState, MouseEvent } from 'react'

import { useAuth, useUser } from '@clerk/clerk-react'
import {
    Avatar,
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery
} from '@mui/material'
import { useNavigate, useLocation } from 'react-router'

import { ReactComponent as ProfileDownArrowIcon } from '../../assets/profile-down-arrow-icon.svg'
import theme from '../../styles/theme'

const settings = [
    { value: 'dashboard', label: 'Dashboard', link: '/dashboard' },
    { value: 'myInvitee', label: 'My Invitee', link: '/invites' },
    { value: 'logout', label: 'Logout' }
]

export default function ProfileSettings() {
    const { isSignedIn, signOut } = useAuth()
    const { user } = useUser()
    const navigate = useNavigate()
    const location = useLocation()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
    const [hideProfileIcon, setHideProfileIcon] = useState(false);

    const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const logoutHandler = async () => {
        setAnchorElUser(null); 
        await signOut().then(async () => {
            navigate('/')
            setHideProfileIcon(true)
        })
    }

    const closeLogoutHandler = (link:any) =>{
        navigate(link)
        setAnchorElUser(null); 
    }

    return (
        <>
            {isSignedIn && (
                <Box sx={{ flexGrow: 0, ml: { xs: 0, sm: 5 } }}>
                   {!hideProfileIcon && <>  <IconButton
                        onClick={() => (!isSmall ? handleOpenUserMenu : null)}
                        sx={{ p: 0 }}
                    >
                        <Avatar src={user?.profileImageUrl} />
                    </IconButton>
                        <Button
                            size="small"
                            variant="text"
                            onClick={handleOpenUserMenu}
                            endIcon={<ProfileDownArrowIcon />}
                            sx={{
                                fontSize: '13px',
                                fontWeight: 'bold',
                                color: 'custom.gray',
                                pl: 1,
                                textTransform: 'capitalize'
                            }}
                        >
                            {user?.firstName}
                        </Button>
                     </>}

                    <Menu
                        sx={{ mt: '45px' }}
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting: any) => {
                            if (location.pathname === '/' ) {
                                return (
                                    <MenuItem
                                        key={setting?.value}
                                        onClick={setting.value !== 'logout' ? () => closeLogoutHandler(setting.link)  : logoutHandler }   
                                    >
                                        <Typography
                                            textAlign="center"
                                            sx={{
                                                textAlign: 'center',
                                                '.MuiTypography-body1': {
                                                    color: '#023E8A',
                                                    fontWeight: 'normal'
                                                }
                                            }}
                                        >
                                            {setting.label}
                                        </Typography>
                                    </MenuItem>
                                )
                            } if (location.pathname !== '/' && setting.value === 'logout') {
                                return (
                                    <MenuItem key={setting.value} onClick={logoutHandler}>
                                        <Typography
                                            textAlign="center"
                                            sx={{
                                                textAlign: 'center',
                                                '.MuiTypography-body1': {
                                                    color: '#023E8A',
                                                    fontWeight: 'normal'
                                                }
                                            }}
                                        >
                                            {setting.label}
                                        </Typography>
                                    </MenuItem>
                                )
                            }
                            return null
                        })}
                    </Menu>
                </Box>
            )}
        </>
    )
}
