import { Typography } from '@mui/material'

export default function Label(props: any) {
    return (
        <Typography
            variant="body2"
            gutterBottom
            sx={{
                font: 'normal normal normal 13px/16px Inter',
                color: 'secondary.light'
            }}
        >
            {props?.title}
        </Typography>
    )
}
