import { useAuth } from '@clerk/clerk-react';
import { useLocation } from 'react-router-dom'

export default function NavItems() {
    const { isSignedIn } = useAuth();
    const location = useLocation();
    const baseNavItems = [
        {
            value: '#',
            label: 'Home',
            type: 'link',
            show: !isSignedIn,
            isSingleNavigation: true
        },
        {
            value: '#about-us',
            label: 'About Us',
            type: 'link',
            show: !isSignedIn,
            isSingleNavigation: true
        },
        {
            value: '#how-it-works',
            label: 'How It works',
            type: 'link',
            show: !isSignedIn,
            isSingleNavigation: true
        },
        {
            value: 'login',
            label: 'Sign In/Sign up',
            type: 'button',
            btnStyle: 'solid',
            show: !isSignedIn,
            isSingleNavigation: false
        },
        {
            value: '#contact-us',
            label: 'Contact Us',
            type: 'button',
            btnStyle: 'outline',
            show: !isSignedIn,
            isSingleNavigation: true
        },
        {
            value: 'dashboard',
            label: 'My Dashboard',
            type: 'link',
            show: isSignedIn,
            isSingleNavigation: false
        }, 
        {
            value: 'invites',
            label: 'My Invites',
            type: 'link',
            show: isSignedIn,
            isSingleNavigation: false
        }
    ]


    const baseNavItems2 = [
        {
            value: '#',
            label: 'Home',
            type: 'link',
            show: isSignedIn &&  location.pathname === '/',
            isSingleNavigation: true
        },
        {
            value: '#about-us',
            label: 'About Us',
            type: 'link',
            show: isSignedIn && location.pathname === '/',
            isSingleNavigation: true
        },
        {
            value: '#how-it-works',
            label: 'How It works',
            type: 'link',
            show: isSignedIn && location.pathname === '/',
            isSingleNavigation: true
        },

        {
            value: '#contact-us',
            label: 'Contact Us',
            type: 'button',
            btnStyle: 'outline',
            show: isSignedIn &&  location.pathname === '/',
            isSingleNavigation: true
        },

        {
            value: 'dashboard',
            label: 'My Dashboard',
            type: 'link',
            show: isSignedIn  &&  location.pathname !== '/',
            isSingleNavigation: false
        },
        {
            value: 'invites',
            label: 'My Invites',
            type: 'link',
            show: isSignedIn  &&  location.pathname !== '/',
            isSingleNavigation: false
        },
        {
            value: 'memories',
            label: 'My memories',
            type: 'link',
            show: isSignedIn  &&  location.pathname !== '/',
            isSingleNavigation: false
        }
    ]

    
    const navItems = isSignedIn  ? [...baseNavItems2] : baseNavItems

    return navItems
}
