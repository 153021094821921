import LoadingButton from '@mui/lab/LoadingButton'

function OutlinedButtons(props: any) {
    return (
        <LoadingButton
            loading={props?.loading}
            variant="outlined"
            sx={{
                height: '40px',
                width: props?.width ? props?.width : '240px',
                font: 'normal normal 600 13px/16px Inter',
                textTransform: 'none',
                borderRadius: '4px',
                color: props?.bgColor ? 'white' : 'primary.light',
                backgroundColor: props?.bgColor ?? 'white',
                fontWeightRegular: 600,
                border: '1px solid #183D85',
                '&:hover': {
                    color: '#0096c7',
                    border: '1px  solid #0096c7'
                },
                ' .MuiLoadingButton-loadingIndicator': {
                    color: 'white'
                }
            }}
            onClick={props?.onClick}
        >
            {props?.text}
        </LoadingButton>
    )
}

export { OutlinedButtons }
