import { Grid } from '@mui/material'

import NoMediaText from '../../../../components/Shared/Typography/NoMediaText'

export default function ViewerVideos(props: any) {
    return (
        <>
            {props?.media?.length > 0 ? (
                <Grid container spacing={2}>
                    {props?.media?.map((item: any) => (
                        <Grid item xs={6} key={item?.url}>
                            <video width="100%" height="200px" controls>
                                <source src={item.url} />
                            </video>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <NoMediaText text="No media to show." />
            )}
        </>
    )
}
