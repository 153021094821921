import { useEffect } from 'react'

import { useAuth, useUser } from '@clerk/clerk-react'
import { useNavigate } from 'react-router-dom'


import UserAccount from '../../../services/auth.service'
import Loader from '../../Shared/Loader'

export default function RedirectHomePage() {
    const { isSignedIn, signOut } = useAuth()
    const { user } = useUser()
    const navigate = useNavigate()

    useEffect(() => {
        if (isSignedIn && isSignedIn !== undefined) {
            redirectHome()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn])

    const redirectHome = async () => {
        const userRegisterData = {
            email: user?.primaryEmailAddress?.emailAddress,
            username: user?.fullName ?? 'Test',
            profile_pic_url: user?.profileImageUrl
        }
        await UserAccount.register(userRegisterData)
            .then(async () => {
                navigate('/dashboard')
            })
            .catch(async () => {
                await signOut().then(async () => {
                    navigate('/')
                })
            })
    }

    return (
        <>
            <Loader />
        </>
    )
}
