import { Typography } from '@mui/material';

export default function EllipsisLabel(props: any) {
    return (
        <Typography
            component="p"
            sx={{
                color: 'white',
                font: props?.font ? props?.font : '11px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1 !important', 
                WebkitBoxOrient: 'vertical'
            }}
        >
            {props?.label}
        </Typography>
    );
}
