import { Box, Link } from '@mui/material'

export default function LinkLoginWithSocial(props: any) {
    return (
        <Box
            sx={{
                width: '100%',
                mt: 1
            }}
        >
            <Link
                onClick={props?.setShowSocialLogin}
                variant="body2"
                sx={{
                    color: '#023E8A',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    fontSize: '11px',
                    fontWeight: '600'
                }}
            >
                Login with social account
            </Link>
        </Box>
    )
}
