import { Box, Grid } from '@mui/material'

import NoMediaText from '../../../../components/Shared/Typography/NoMediaText'

export default function ViewerAudios(props: any) {
    return (
        <>
            {props?.media?.length > 0 ? (
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyItems="center"
                    alignItems="center"
                >
                    {props?.media?.map((item: any) => (
                        <Grid item xs={12} key={item.url} textAlign="center">
                            <Box alignItems="center" sx={{ width: '100%' }}>
                                <audio controls>
                                    <source src={item.url} type="video/mp4" />
                                </audio>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <NoMediaText text="No media to show." />
            )}
        </>
    )
}
