import * as React from 'react'

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import IconButton from '@mui/material/IconButton'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'

import NoMediaText from '../../../../components/Shared/Typography/NoMediaText'
import DownloadFromUrl from '../../../../hooks/DownloadFromUrl'

export default function ViewerImages(props: any) {
    return (
        <>
            {props?.media?.length > 0 ? (
                <ImageList cols={4}>
                    {props?.media?.map((item: any) => (
                        <ImageListItem key={item.url}>
                            <img
                                src={`${item.url}?w=100&fit=crop&auto=format`}
                                srcSet={`${item.url}?w=100&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.key}
                                loading="lazy"
                            />
                            <ImageListItemBar
                                sx={{ bgcolor: 'transparent' }}
                                actionIcon={
                                    <IconButton
                                        onClick={() => DownloadFromUrl(item)}
                                        sx={{
                                            color: 'rgba(255, 255, 255, 0.54)'
                                        }}
                                        aria-label={`info about ${item.key}`}
                                    >
                                        <ArrowCircleDownIcon />
                                    </IconButton>
                                }
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            ) : (
                <NoMediaText text="No media to show." />
            )}
        </>
    )
}
