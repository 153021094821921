import { Box, Grid, Typography, useMediaQuery,Container } from '@mui/material'

import FlagIcon from '../../../assets/Home/home-flags-right.svg'
import FlagSmallIcon from '../../../assets/Home/home-flags-small-right.svg'
import InviteIcon from '../../../assets/Home/invite.png'
import MakeItEasy from '../../../components/Shared/Cards/MakeItEasyCard'
import theme from '../../../styles/theme'

export function WeMakeItEasy() {

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Grid
            container
            sx={{
                background: `#E2E2E250`,
                '&:after': {
                    background: `linear-gradient(-45deg, transparent 16px, #E2E2E250 0),
linear-gradient(45deg, transparent 16px, #E2E2E250  0)`,
                    backgroundRepeat: 'repeat-x',
                    backgroundPosition: 'left bottom',
                    content: `''`,
                    display: 'block',
                    width: '113%',
                    height: '16px',
                    position: 'relative',
                    top: '15px',
                    left: '0px',
                    backgroundSize: '15px 32px'
                }
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    position: 'relative',
                    textAlign: 'right'
                }}
            >
                <Box
                    component="img"
                    src={isSmall ? FlagSmallIcon : FlagIcon}
                />
            </Box>
            <Grid item xs={12} sm={12}>
                <Box
                    data-sal="slide-right"
                    data-sal-delay="300"
                    data-sal-easing="ease-out-back"
                    sx={{
                        textAlign: 'center',
                        pt: { xs: 5, sm: 0 },
                        pb: { xs: 5, sm: 10 },
                        px: { xs: 6, sm: 3, md: 25 }
                    }}
                >
                    <Typography variant="h3">
                        We Make It Easy:
                    </Typography>


                </Box>
                <Container sx={{pb:5}}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{justifyContent:"center"}}>
                        <MakeItEasy 
                           imageUrl="https://assets-global.website-files.com/60545738215c60ea73898e74/633b20c8a3541d956de9f03c_streamlinehq-bell-essential-icons-48.SVG"
                           title="Auto-Reminders"
                           content="Auto-reminder emails are sent to the invitees before the deadline as a friendly reminder to participate in the video montage."
                           />
                        </Grid>
                        <Grid item xs={12} md={6} >
                           <MakeItEasy 
                           imageUrl="https://assets-global.website-files.com/60545738215c60ea73898e74/633b21c6a9493b02ce3510ab_streamlinehq-rolloflex-people-48.SVG"
                           title="Import Contacts"
                           content="Easily allows users to quickly upload and sync their contact lists, streamlining communication and engagement."
                           
                           />
                        </Grid>
                        <Grid item xs={12} md={6} >
                        <MakeItEasy 
                           imageUrl={InviteIcon}
                           title="Manual Invites"
                           content="Manual Invites helps us to invite others by sharing event link via various social media platforms."
                           />
                        </Grid>
                        <Grid item xs={12} md={6} >
                           <MakeItEasy 
                           imageUrl="https://assets-global.website-files.com/60545738215c60ea73898e74/633b219a631f6431882ace51_streamlinehq-bag-present-essential-icons-48.SVG"
                           title="Gifting the Video"
                           content="Participants have the option to send gift in the form of videos for the recipient."
                           />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    )
}