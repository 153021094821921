import { useEffect, useState } from 'react'

import { useAuth } from '@clerk/clerk-react'
import { Box } from '@mui/material'
import { Container } from '@mui/system'
import { useParams } from 'react-router-dom'

import { HeaderCard } from './HeaderCard'
import { SideMenu } from './SideMenu'
import Loader from '../../components/Shared/Loader'
import EventService from '../../services/event.service'

function ViewEvent() {
    const [isLoading, setIsLoading] = useState(false)
    const { isSignedIn } = useAuth()
    const { eventId } = useParams()
    const [eventDetails, setEventDetails] = useState()
    const [eventInvites, setEventInvites] = useState()
    const [eventGroups, setEventGroups] = useState()

    const getEventDetailsById = async () => {
        await EventService.getEventById(eventId, false)
            .then((response: any) => {
                setEventDetails(response.data)
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    const getEventInvites = async () => {
        await EventService.getEventInvites(eventId)
            .then((response: any) => {
                setEventInvites(response.data)
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    const getEventGroups = async () => {
        await EventService.getEventGroups(eventId)
            .then((response: any) => {
                setEventGroups(response.data)
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    const loadData = async () => {
        await Promise.all([
            getEventDetailsById(),
            getEventInvites(),
            getEventGroups()
        ])
        setIsLoading(false)
    }

    const updateEventDataCallBack = () => {
        getEventDetailsById()
    }

    useEffect(() => {
        if (!isLoading && eventId) {
            setIsLoading(true)
            loadData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId])

    return (
        <>
            {isLoading && isSignedIn ? (
                <Loader />
            ) : (
                <Box sx={{ width: '100%' }}>
                    <Container
                        sx={{ padding: { xs: '0', sm: '2% 10% !important' } }}
                    >
                        {eventDetails && (
                            <>
                                <HeaderCard
                                    event={eventDetails}
                                    eventInvites={eventInvites}
                                    eventGroups={eventGroups}
                                />
                                <SideMenu
                                    event={eventDetails}
                                    eventInvites={eventInvites}
                                    eventGroups={eventGroups}
                                    updateEventDataCallBack={
                                        updateEventDataCallBack
                                    }
                                />
                            </>
                        )}
                    </Container>
                </Box>
            )}
        </>
    )
}

export { ViewEvent }
