import { useEffect, useState } from 'react'

import { useAuth, useUser } from '@clerk/clerk-react'
import { Container } from '@mui/system'
import { useNavigate } from 'react-router-dom'

import { ActiveEvents } from './ActiveEvents/Index'
import { ArchiveEvents } from './ArchiveEvents/Index'
import { PublishedEvents } from './PublishedEvents/Index'
import SwitchButtons from '../../components/Shared/Button/SwitchButtons'
import Loader from '../../components/Shared/Loader'
import UserAccount from '../../services/auth.service'
import EventService from '../../services/event.service'

function Dashboard() {
    const [type, setType] = useState<string>('Active Events')
    const { isSignedIn, signOut } = useAuth()
    const { user } = useUser()
    const [events, setEvents] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    

    const options = [
        { label: 'Active Events', value: 'Active Events' },
        { label: 'Published Events', value: 'Published Events' },
        { label: 'Archive Events', value: 'Archive Events' }
    ]

    useEffect(() =>{
        redirectHome()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isSignedIn, user])

    const redirectHome = async () => {
        const userRegisterData = {
            email: user?.primaryEmailAddress?.emailAddress,
            username: user?.fullName ?? 'Test',
            profile_pic_url: user?.profileImageUrl
        }
        await UserAccount.register(userRegisterData)
            .then(async () => {
                navigate('/dashboard')
            })
            .catch(async () => {
                await signOut().then(async () => {
                    navigate('/')
                })
            })
    }

    async function getEvents() {
        return EventService.getEvents(user?.primaryEmailAddress?.emailAddress as string);
    }

    const fetchData = async () => {
        try {
            if (isSignedIn) {
                const eventsResponse = await getEvents();
                setEvents(eventsResponse.data);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        fetchData();
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isSignedIn])

    return (
        <Container sx={{ width: '100%', mt: 2 }}>
            {isLoading && isSignedIn && (
                <>
                    <Loader />
                </>
            )}
            {!isLoading && isSignedIn && (
                <>
                    <SwitchButtons options={options} setType={setType} />
                    <>
                        {type === 'Active Events' ? (
                            <ActiveEvents events={events} />
                        ) : type === 'Archive Events' ? (
                            <ArchiveEvents events={events} />
                        ) : (
                            <PublishedEvents events={events}/>
                        )}
                    </>
                </>
            )}
        </Container>
    )
}
export { Dashboard }
