import { useEffect, useState } from 'react'

import { useAuth } from '@clerk/clerk-react'
import { Box } from '@mui/material'
import { Container } from '@mui/system'
import { useParams } from 'react-router-dom'

import { HeaderCard } from './HeaderCard'
import { UploadMedia } from './UploadMedia'
import Loader from '../../components/Shared/Loader'
import EventService from '../../services/event.service'

function Upload() {
    const [isLoading, setIsLoading] = useState(false)
    const { isSignedIn } = useAuth()
    const { eventId } = useParams()
    const [eventDetails, setEventDetails] = useState()

    const getEventDetailsById = async () => {
        await EventService.getEventById(eventId)
            .then((response: any) => {
                setEventDetails(response.data)
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    const loadData = async () => {
        await Promise.all([getEventDetailsById()])
        setIsLoading(false)
    }

    useEffect(() => {
        if (!isLoading) {
            setIsLoading(true)
            loadData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId])

    return (
        <>
            {isLoading && isSignedIn ? (
                <Loader />
            ) : (
                <Box sx={{ width: '100%' }}>
                    <Container
                        sx={{ padding: { xs: '0', sm: '2% 10% !important' } }}
                    >
                        {eventDetails && (
                            <>
                                <HeaderCard event={eventDetails} />
                                <UploadMedia event={eventDetails} />
                            </>
                        )}
                    </Container>
                </Box>
            )}
        </>
    )
}

export { Upload }
