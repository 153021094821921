import { Grid, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'

import ContactForm from './ContactForm'
import ContactCustomerServiceImg from '../../../../assets/Home/contact-customer-service-icon.png'
import ContactUsBgSmall from '../../../../assets/Home/contact-us-bg-small.svg'
import ContactUsBg from '../../../../assets/Home/contact-us-bg.svg'
import FlagSmallIcon from '../../../../assets/Home/home-flags-left-small.png'
import FlagIcon from '../../../../assets/Home/home-flags-left.png'
import { ReactComponent as SmileStarsIcon } from '../../../../assets/Home/smile-stars-icon.svg'
import theme from '../../../../styles/theme'

export default function Contact() {
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <>
            <Box
                sx={{
                    backgroundImage: {
                        xs: `url(${ContactUsBgSmall})`,
                        sm: `url(${ContactUsBg})`
                    },
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: { xs: '1366px', sm: '1270px' },
                    width: '100%',
                    position: 'relative',
                    mt: { xs: '-100px', sm: '-258px', md: '-258px' }
                }}
            >
                <Box
                    id="contact-us"
                    sx={{
                        width: '100%',
                        position: 'relative',
                        textAlign: 'left',
                        top: { xs: '52px', sm: '246px', md: '270px' }
                    }}
                >
                    <Box
                        component="img"
                        src={isSmall ? FlagSmallIcon : FlagIcon}
                    />
                </Box>
                <Box
                    sx={{
                        py: 1,
                        px: { xs: 2, sm: 3, md: 25 },
                        mt: { xs: '0', sm: '250px', md: '250px' },
                        textAlign: { xs: 'center', sm: 'left' }
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid item xs={12} sm={6} md={5}>
                            <Typography
                                variant="h3"
                                sx={{
                                    pt: 3,
                                    color: 'common.white'
                                }}
                            >
                                <SmileStarsIcon /> We can help you!
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    color: 'common.white'
                                }}
                            >
                                Special Moments enable you to use the
                                application on various occasions other than just
                                birthdays.
                            </Typography>
                            <Box
                                component="img"
                                src={ContactCustomerServiceImg}
                                sx={{ mt: 3, width: { xs: '100%' } }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={7}>
                            <ContactForm />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}
