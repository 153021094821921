import { SyntheticEvent, useState } from 'react'

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import SendIcon from '@mui/icons-material/Send'
import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Hidden,
    Paper,
    Tabs
} from '@mui/material'

import { EditEvent } from './Edit/Index'
import { InviteParticipants } from './InviteParticipants/Index'
import { ManageUploads } from './ManageUploads/Index'
import { PublishEvent } from './UploadPublish/Index'
import { SideNavBarTab } from '../../components/Shared/Tabs/StyledTab'
import { TabPanel } from '../../components/Shared/Tabs/TabPanel'

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`
    }
}

function SideMenu(props: any) {
    const [value, setValue] = useState(0)

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <>
            <Hidden only="xs">
                <Box
                    sx={{ flexGrow: 1, display: 'flex', height: '100%', my: 2 }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{
                            width: '300px',
                            float: 'left',
                            height: '190px',
                            backgroundColor: 'white',
                            mr: 2,
                            pt: 2,
                            borderRadius: '10px',
                            textAlign: 'left',
                            boxShadow: '0px 0px 30px #0000000D'
                        }}
                    >
                        <SideNavBarTab
                            label="Invite Participants"
                            {...a11yProps(0)}
                        />
                        <SideNavBarTab
                            label="Manage Uploads"
                            {...a11yProps(1)}
                        />
                        <SideNavBarTab label="Edit Event" {...a11yProps(2)} />
                        <SideNavBarTab
                            label="Upload & Publish"
                            {...a11yProps(2)}
                        />
                    </Tabs>

                    <Box
                        sx={{
                            backgroundColor: 'white',
                            width: '100%',
                            height: '100%',
                            borderRadius: '10px',
                            boxShadow: '0px 0px 30px #0000000D'
                        }}
                    >
                        <TabPanel value={value} index={0}>
                            <InviteParticipants
                                event={props?.event}
                                eventInvites={props?.eventInvites}
                                eventGroups={props?.eventGroups}
                            />
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <ManageUploads
                                event={props?.event}
                                eventInvites={props?.eventInvites}
                                eventGroups={props?.eventGroups}
                            />
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <EditEvent
                                event={props?.event}
                                updateEventDataCallBack={
                                    props?.updateEventDataCallBack
                                }
                            />
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                            <PublishEvent
                                event={props?.event}
                                updateEventDataCallBack={
                                    props?.updateEventDataCallBack
                                }
                            />
                        </TabPanel>
                    </Box>
                </Box>
            </Hidden>

            <Hidden smUp>
                <Box
                    sx={{
                        p: 0,
                        backgroundColor: 'white',
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px',
                        boxShadow: '0px 0px 30px #0000000D'
                    }}
                >
                    <TabPanel value={value} index={0}>
                        <InviteParticipants
                            event={props?.event}
                            eventInvites={props?.eventInvites}
                            eventGroups={props?.eventGroups}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <ManageUploads
                            event={props?.event}
                            eventInvites={props?.eventInvites}
                            eventGroups={props?.eventGroups}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <EditEvent
                            event={props?.event}
                            updateEventDataCallBack={
                                props?.updateEventDataCallBack
                            }
                        />
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                        <PublishEvent
                            event={props?.event}
                            updateEventDataCallBack={
                                props?.updateEventDataCallBack
                            }
                        />
                    </TabPanel>
                </Box>

                <Paper
                    sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
                    elevation={3}
                >
                    <BottomNavigation
                        showLabels
                        value={value}
                        sx={{ height: '80px' }}
                        onChange={(event, newValue) => {
                            setValue(newValue)
                        }}
                    >
                        <BottomNavigationAction
                            label="Invite"
                            icon={<GroupAddIcon />}
                        />
                        <BottomNavigationAction
                            label="Uploads"
                            icon={<FileUploadIcon />}
                        />
                        <BottomNavigationAction
                            label="Edit"
                            icon={<AutoFixHighIcon />}
                        />
                        <BottomNavigationAction
                            label="Publish"
                            icon={<SendIcon />}
                        />
                    </BottomNavigation>
                </Paper>
            </Hidden>
        </>
    )
}

export { SideMenu }
