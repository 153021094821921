import { useEffect } from 'react'

import { Box, Grid, Hidden, Typography } from '@mui/material'
import sal from 'sal.js'

import CelebrationIcon from '../../../assets/Home/celebration-icon.png'
import { ReactComponent as CelebrationSmileIcon } from '../../../assets/Home/celebration-smile-icon.svg'
import RectangleBoarderIcon from '../../../assets/Home/home-rectangle-box-bg.png'
import RectangleBoarderSmallIcon from '../../../assets/Home/home-rectangle-box-small-bg.png'
import SelfieIcon from '../../../assets/Home/home-selfie-icon.png'
import { ReactComponent as PaperPlanColorBigIcon } from '../../../assets/Home/paper-plane-solid-color-big-ion.svg'
import { ReactComponent as PaperPlanColorIcon } from '../../../assets/Home/paper-plane-solid-color-ion.svg'

export default function About() {
    useEffect(() => {
        sal({
            root: null,
            threshold: 1,
            once: false
        })
    }, [])

    return (
        <Box
            id="about-us"
            sx={{
                px: { xs: 0, sm: 3, md: 15 },
                pt: { xs: 20, sm: 10, md: 25 },
                pb: { xs: 30, sm: 10, md: 15 }
            }}
        >
            <Box
                sx={{
                    width: { xs: '330px', sm: '100%', md: '1000px' },
                    height: { xs: '408px', sm: '300px' },
                    backgroundImage: {
                        xs: `url(${RectangleBoarderSmallIcon})`,
                        sm: `url(${RectangleBoarderIcon})`
                    },
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    px: 3,
                    m: 'auto'
                }}
            >
                <Grid container direction="row" spacing={2}>
                    <Hidden only="xs">
                        <Grid item xs={12} sm={4}>
                            <Box
                                sx={{
                                    display: 'flex'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '200px',
                                        height: '300px',
                                        background: `url(${SelfieIcon})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        m: 'auto',
                                        position: 'relative'
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'relative',
                                        left: '-15px',
                                        top: '230px'
                                    }}
                                >
                                    <PaperPlanColorIcon />
                                </Box>
                            </Box>
                        </Grid>
                    </Hidden>

                    <Grid item xs={12} sm={8} sx={{ pt: 0, m: 0 }}>
                        <Hidden only="xs">
                            <Box
                                component="img"
                                src={CelebrationIcon}
                                sx={{
                                    position: 'relative',
                                    right: '-22px',
                                    textAlign: 'right',
                                    top: '-38px',
                                    float: 'right'
                                }}
                            />
                        </Hidden>
                        <Typography
                            variant="h2"
                            sx={{ p: { xs: 0, sm: 4 } }}
                            data-sal="slide-right"
                            data-sal-delay="300"
                            data-sal-easing="ease-out-back"
                        >
                            We are the masters of the event creating and
                            celebrating. <CelebrationSmileIcon />
                        </Typography>

                        <Typography variant="subtitle2">
                            We believe that life’s milestones are worth
                            celebrating. We understand that every event is
                            unique, which is why we offer great features to
                            collect memories from your circle. Our mission is to
                            make your special day the one you’ll never forget.
                        </Typography>
                    </Grid>

                    <Hidden smUp>
                        <Grid item xs={12} sm={4}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'right'
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        left: -20,
                                        top: 20
                                    }}
                                >
                                    <PaperPlanColorBigIcon />
                                </Box>
                                <Box
                                    sx={{
                                        width: '200px',
                                        height: '300px',
                                        background: `url(${SelfieIcon})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        position: 'relative',
                                        right: -10,
                                        top: -30
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Hidden>
                </Grid>
            </Box>
        </Box>
    )
}
