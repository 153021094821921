import { SyntheticEvent, useState } from 'react'

import { Box, Divider, Tab, Tabs } from '@mui/material'

import { Invite } from './Invite/Index'
import { Invited } from './Invited/Index'
import { TabPanel } from '../../../components/Shared/Tabs/TabPanel'

function InviteParticipants(props: any) {
    const [value, setValue] = useState(0)
    const [invitedSubTab, setInvitedSubTab] = useState(0)

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const addCallBackEvent = (topLevel: any, innerTab: any) => {
        setValue(topLevel)
        setInvitedSubTab(innerTab)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                centered
                sx={{
                    '.Mui-selected': {
                        color: 'primary.light',
                        font: 'normal normal medium 16px/20px Inter'
                    }
                }}
                TabIndicatorProps={{
                    color: 'primary.light'
                }}
            >
                <Tab
                    label="Invite"
                    sx={{ width: '50%', textTransform: 'capitalize' }}
                />
                <Tab
                    label="Invited"
                    sx={{ width: '50%', textTransform: 'capitalize' }}
                />
            </Tabs>
            <Divider />

            <Box>
                <TabPanel value={value} index={0}>
                    <Invite
                        event={props?.event}
                        eventInvites={props?.eventInvites}
                        eventGroups={props?.eventGroups}
                        addCallBackEvent={addCallBackEvent}
                    />
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <Invited
                        event={props?.event}
                        eventInvites={props?.eventInvites}
                        eventGroups={props?.eventGroups}
                        invitedSubTab={invitedSubTab}
                    />
                </TabPanel>
            </Box>
        </Box>
    )
}
export { InviteParticipants }
