import { useState, useEffect } from 'react';

import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import EventImageUploader from './EventImageUploader'
import TimeCounting from './TimeCounting';
import BirthdayCakeIcon from '../../assets/birthday-cake.svg';
import EventViewHeaderCardBg from '../../assets/EventViewHeaderCardBg.svg';
import { ReactComponent as SingleUserIcon } from '../../assets/single-user-icon.svg';
import EllipsisLabel from '../../components/Shared/Label/EllipsisLabel';
import  Uploader from '../../hooks/Uploader';
import EventService from '../../services/event.service';

// eslint-disable-next-line max-lines-per-function
function HeaderCard(props: any) {
    const navigate = useNavigate();
    const time = new Date(props?.event?.deadline);
    time.setSeconds(time.getSeconds());
    const [tempEventImage, setTempEventImage] = useState('');
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });
    const eventDate = moment(props?.event?.deadline);
    const now = moment();
    const deadline = now.isBefore(eventDate) ? eventDate : now.clone().endOf('day');

    useEffect(() => {
        const interval = setInterval(() => {
            const timeLeft = moment.duration(deadline.diff(moment()));
            setTimeLeft({
                days: timeLeft.days(),
                hours: timeLeft.hours(),
                minutes: timeLeft.minutes(),
                seconds: timeLeft.seconds()
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [deadline]);

    const handleFileSelect = async (files: FileList | null) => {
        if (!files || files.length === 0) {
            return;
        }

        try {
            const file = files[0];
            const fileName = file.name;
            const uploader = new Uploader({ fileName, file });
            await uploader.start();
            const s3Url = uploader.getS3Url();
            setTempEventImage(s3Url);
            const request = { event_image_url: s3Url };
            await EventService.eventUpdate(props?.event.event_id, request);
            toast.success('Profile image uploaded!');
        } catch (error) {
            toast.error('Failed to upload image');
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: 'primary.light',
                borderRadius: { xs: '0', sm: '4px' }
            }}
        >
            <Card
                sx={{
                    display: 'flex',
                    background: `transparent url(${EventViewHeaderCardBg}) 0% 0% no-repeat padding-box`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: { xs: '440px', sm: '240px' },
                    width: '100%'
                }}
            >
                <Grid
                    container
                    component="main"
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <Grid item xs={12} sm={8}>
                        <Box
                            sx={{
                                padding: {
                                    xs: '30px 30px 0 30px',
                                    sm: '40px 30px'
                                },
                                width: '100%'
                            }}
                        >
                            <Grid
                                container
                                component="main"
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={3}
                            >
                                <Grid item xs={4} md={3}>
                                    <EventImageUploader
                                        tempEventImage={tempEventImage}
                                        eventImageUrl={props?.event?.event_image_url}
                                        handleFileSelect={handleFileSelect}
                                    />
                                </Grid>
                                <Grid item xs={8} md={9} sx={{ pt: 1 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 1
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={BirthdayCakeIcon}
                                            sx={{
                                                height: '30px',
                                                width: '30px',
                                                display: 'inline-block',
                                                mr: 1
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                bgcolor: '#ffffff99',
                                                borderRadius: '4px'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    font: 'normal normal normal 11px/14px Inter',
                                                    display: 'inline-block',
                                                    p: 0.5,
                                                    m: 'auto'
                                                }}
                                            >
                                                {moment(
                                                    props?.event?.actual_event_date
                                                ).format('MM-DD-YYYY')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <EllipsisLabel
                                        font="normal normal 600 19px/24px Inter"
                                        label={props?.event.name}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex flex-row',
                                            alignItems: 'center',
                                            mt: 1
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                mr: 2
                                            }}
                                        >
                                            <SingleUserIcon />
                                            <Typography
                                                sx={{
                                                    font: 'normal normal normal 11px/14px Inter',
                                                    color: '#AABBCC',
                                                    pl: '5px'
                                                }}
                                            >
                                                {
                                                    props?.eventInvites?.invites?.length
                                                }{' '}
                                                Participants Invited
                                            </Typography>
                                        </Box>
                                        <Typography
                                            sx={{
                                                font: 'normal normal normal 11px/14px Inter',
                                                color: '#AABBCC',
                                                pt: '5px'
                                            }}
                                        >
                                            Target Date for Publishing event{' '}
                                            {moment(props?.event?.event_date).format('MM-DD-YYYY')}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                font: 'normal normal normal 11px/14px Inter',
                                                color: '#AABBCC',
                                                pt: '5px'
                                            }}
                                        >
                                            {(props?.event?.message)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} justifyItems="right" justifyContent="right">
                        <TimeCounting
                            time={timeLeft}
                            onClick={() =>
                                navigate(`/upload-images/${props?.event?.event_id}`)
                            }
                        />
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
}

export { HeaderCard };
