import { Box, CardMedia, Typography } from '@mui/material'
 
const WeMakeItEasyCard = ({
    imageUrl, title, content
}: any) => (
    <Box sx={{ display: 'flex',gap:'1rem', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center', md: 'flex-start' }, padding: '20px' }}>
        <CardMedia
            component="img"
            image={imageUrl}
            alt="img"
            sx={{ width: '100px', height: '100px', alignSelf:{xs:'center'}, backgroundColor: '#f0f0f0', borderRadius:'50%' }}
        />
        <Box sx={{pt:'10px', textAlign: {xs:'center', md:'left'}}}>
            <Typography variant="h6" fontSize="1rem" fontWeight='bold'>{title}</Typography>
            <Typography variant="body1">
                {content}
            </Typography>
        </Box>
    </Box>
)
 
export default WeMakeItEasyCard