import AWS from 'aws-sdk'

const s3bucket = new AWS.S3({
    accessKeyId: process.env.REACT_APP_PUBLIC_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_PUBLIC_SECRET_ACCESSKEY,
    region: process.env.REACT_APP_PUBLIC_REGION,
    signatureVersion: 'v4'
})

export default function DownloadFromUrl(media: any) {
    const params: any = {
        Bucket: process.env.REACT_APP_PUBLIC_S3_BUCKET_NAME,
        Key: media.key
    }

    s3bucket.getObject(params, (err, data: any) => {
        if (!err) {
            const blob = new Blob([data.Body], { type: data.ContentType })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = media.key
            link.click()
        }
    })
}
