import { Box, Grid } from '@mui/material'

import EventDetailsCard from '../../components/Shared/Cards/EventDetailsCard'

function EventCard(props: any) {
    return (
        <Box sx={{ width: '100%', my: 3 }}>
            <Grid container spacing={2}>
                {props?.events.map((event: any) => (
                    <EventDetailsCard
                        key={event.event_id}
                        eventName={event.name}
                        eventImageUrl={event.event_image_url}
                        eventDate={event?.event_date}
                        redirectUrl={`/view-event/${event.event_id}`}
                        pastEvent={props?.past}
                    />
                ))}
            </Grid>
        </Box>
    )
}

export { EventCard }
