import {
    Avatar,
    Card,
    Chip,
    Grid,
    LinearProgress,
    Typography
} from '@mui/material'

export default function SelectedFilesCard(props: any) {
    return (
        <>
            {props?.files &&
                props?.files?.map((file: any) => (
                    <Card
                        key={`${file.name}-${file.lastModified}`}
                        sx={{
                            boxShadow: '0px 0px 30px #0000000D',
                            border: '1px solid',
                            borderColor: 'secondary.contrastText',
                            borderRadius: '4px',
                            p: 2,
                            my: 1
                        }}
                    >
                        <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="left"
                            alignItems="center"
                        >
                            <Grid item xs={2}>
                                <Avatar
                                    src={URL.createObjectURL(file)}
                                    sx={{ borderRadius: 0 }}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        font: 'normal normal normal 13px/14px Inter',
                                        color: 'custom.dark'
                                    }}
                                >
                                    {file?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                {!props?.isUploadStarted ? (
                                    <Chip
                                        label="Not started"
                                        variant="outlined"
                                        sx={{
                                            font: 'normal normal medium 11px/14px Inter',
                                            color: '#7FD14D',
                                            bgcolor: '#f2faed',
                                            borderColor: '#f2faed',
                                            borderRadius: '4px',
                                            height: '26px'
                                        }}
                                    />
                                ) : (
                                    <LinearProgress />
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                ))}
        </>
    )
}
