import { useState } from 'react'

import { useSignUp } from '@clerk/clerk-react'
import { Box } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'

import { APIResponseError, parseError } from '../../../hooks/ClerkErrors'
import { OutlinedButtons } from '../../Shared/Button/OutlinedButtons'
import ErrorValidationMessage from '../../Shared/ErrorMessage/ErrorValidationMessage'
import CustomHeading from '../../Shared/Typography/CustomHeading'
import CustomText from '../../Shared/Typography/CustomText'

export default function OTP() {
    const { signUp } = useSignUp()
    const [otp, setOTP] = useState('')
    const [error, setError] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)

    const handleOTPValidation = async () => {
        setBtnLoading(true)
        await signUp
            ?.attemptEmailAddressVerification({
                code: otp
            })
            .then(async (response: any) => {
                if (
                    response?.verifications.emailAddress.status === 'verified'
                ) {
                    window.location.href = '/redirecting-home'
                }
            })
            .catch((err) => {
                setError(parseError(err as APIResponseError))
                setBtnLoading(false)
            })
    }

    return (
        <Box sx={{ width: '100', textAlign: 'center', mb: 8 }}>
            <CustomHeading heading="Please check your email" />

            <CustomText
                text="We’ve emailed a 6-digit confirmation code Please enter the code
                in below box to verify your email."
            />

            <Box
                component="form"
                noValidate
                sx={{ textAlign: 'center', px: 0, mt: 6 }}
            >
                <MuiOtpInput
                    value={otp}
                    onChange={(e) => setOTP(e)}
                    length={6}
                    inputMode="numeric"
                    sx={{
                        p: 0,
                        display:'flex',
                        gap:'8px',
                        '.MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            borderBottom: '1px solid',
                            borderRadius: 'initial',
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                            marginBottom: '7px'
                        }
                    }}
                />
                {error && <ErrorValidationMessage message={error} />}

                <Box sx={{ mb: 2, mt: 8 }} component="div">
                    <OutlinedButtons
                        loading={btnLoading}
                        bgColor="#023E8A"
                        text="Verify"
                        onClick={handleOTPValidation}
                    />
                </Box>
            </Box>
        </Box>
    )
}
